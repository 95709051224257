<template>
  <div>
    <div class="main-wrapper">
      <sidebar-component classMenu="BillableMethod"></sidebar-component>
      <div class="page-wrapper">
        <navbar-component></navbar-component>
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12 pe-2">
            <div class="card">
              <div class="card-header">
                <h6
                  class="card-title mb-0 float-start"
                  style="padding-left: 25px"
                >
                  Billable Method Edit
                </h6>
              </div>
              <div class="card-body">
                <!--begin::loading-data-->
                <div v-if="isLoading" class="d-flex align-items-center">
                  <div
                    class="spinner-border ms-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                  <strong>{{ $t("loadingTxt") }}...</strong>
                </div>
                <!--end::loading-data-->
                <form
                  class="forms-sample"
                  @submit.prevent="submitData"
                  method="POST"
                >
                  <div class="card">
                    <div class="card-header">
                      <h6>Manage Billable Method</h6>
                    </div>
                    <div class="card-body">
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3 "
                          >{{ this.$t("companyTxt") }}
                        </label>
                        <div class="col-sm-6">
                        
                          <v-select
                          :options="fetchCompany"
                          :disabled="this.company_id!=''"
                          v-model="company"
                          label="name"
                          return-object
                          @search="asyncSearchCompany"
                        
                          
                        ></v-select>


                        </div>
                        <div
                          v-for="error in errors.company_id"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>

                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >{{ this.$t("descriptionTxt") }}
                        </label>
                        <div class="col-sm-6">
                          <input
                            type="text"
                            class="form-control"
                            v-model="forms.description"
                          />
                        </div>
                        <div
                          v-for="error in errors.description"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >File Upload Cross Reference</label
                        >
                        <div class="col-sm-6">
                          <input
                            type="text"
                            class="form-control"
                            v-model="forms.file_reference"
                          />
                        </div>
                        <div
                          v-for="error in errors.file_reference"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3">Rating Method</label>
                        <div class="col-sm-6">
                          <!-- <v-select
                            v-model="rati"
                            label="code"
                            return-object
                          ></v-select> -->

                          <v-select
                          :options="fetchratingmethod"
                          v-model="rating_method"
                          label="value"
                          return-object
                            @search="asyncloadRatingMethod"
                          
                        ></v-select>

                        </div>
                        <div
                          v-for="error in errors.rating_method"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >Default Allocation Method</label
                        >
                        <div class="col-sm-6">
                         

                          <v-select
                          :options="fetchDefaulAlocationMethod"
                          v-model="default_alocation_method"
                          label="value"
                          return-object
                            @search="asyncloadDefaultAlocationMethod"
                          
                        ></v-select>

                        </div>
                        <div
                          v-for="error in errors.default_method"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <h6>Rating Method Information</h6>
                    </div>
                    <div class="card-body">
                      

                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-7"
                          >{{ this.$t("billableMethodDateRate") }}
                        </label>
                        <div class="col-sm-2">
                          <v-select
                          :options="fetchBillableMethodDateRate"
                          v-model="billable_method_rate"
                          label="value"
                          return-object
                            @search="asyncloadBillableMethodeDateRate"
                          
                        ></v-select>
                        </div>
                        <div
                          v-for="error in errors.date_rate"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-7"
                          >{{ this.$t("billableMethodSkuRating") }}
                        </label>
                        <div class="col-sm-2">
                          <div class="form-check form-check-inline">
                            <input
                              type="radio"
                              class="form-check-input"
                              id="future"
                              :value="'yes'"
                              v-model="forms.shipment_rating"
                            />
                            <label class="form-check-label" for="future">
                              Yes
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              type="radio"
                              :value="'no'"
                              class="form-check-input"
                              id="future"
                              v-model="forms.shipment_rating"
                            />
                            <label class="form-check-label" for="future">
                              No
                            </label>
                          </div>
                        </div>
                        <div
                          v-for="error in errors.rating_method"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-7"
                          >{{ this.$t("billableMethodSkuRate") }}
                        </label>
                        <div class="col-sm-2">
                          <div class="form-check form-check-inline">
                            <input
                              type="radio"
                              class="form-check-input"
                              id="future"
                              :value="'yes'"
                              v-model="forms.sku_rate"
                            />
                            <label class="form-check-label" for="future">
                              Yes
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              type="radio"
                              :value="'no'"
                              class="form-check-input"
                              id="future"
                              v-model="forms.sku_rate"
                            />
                            <label class="form-check-label" for="future">
                              No
                            </label>
                          </div>
                        </div>
                        <div
                          v-for="error in errors.sku_rate"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <h6>Shipment Rate</h6>
                    </div>
                    <div class="card-body">
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >Billable Tariff
                        </label>
                        <div class="col-sm-3">
                       

                        <v-select
                          :options="fetchBillableTariff"
                          v-model="billableTariff"
                          label="tariff_name"
                          return-object
                          @search="asyncSearchBillableTariff"
                          @input="loadBillableTariff"
                          
                        ></v-select>

                        </div>
                        <div
                          v-for="error in errors.billable_tariff"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >Weight Selection Limits
                        </label>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroup-sizing-default"
                                >Min</span
                              >
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              :placeholder="'Min Weight'"
                              v-model="forms.weight_limit_min"
                            />
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroup-sizing-default"
                                >Max</span
                              >
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              :placeholder="'Max Weight'"
                              v-model="forms.weight_limit_max"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2">
                         
                          <v-select
                          :options="fetchWeight"
                          v-model="weight"
                          label="value"
                          return-object
                            @search="asyncloadBillableWeight"
                          
                        ></v-select>

                        </div>
                        <div
                          v-for="error in errors.weight_limit_weight"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >Volume Selection limits
                        </label>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroup-sizing-default"
                                >Min</span
                              >
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              :placeholder="'Min Volume'"
                              v-model="forms.volume_limit_min"
                            />
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroup-sizing-default"
                                >Max</span
                              >
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              :placeholder="'Max Volume'"
                              v-model="forms.volume_limit_max"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <v-select
                          :options="fetchVolume"
                          v-model="volume"
                          label="value"
                          return-object
                            @search="asyncloadBillableVolume"
                          
                        ></v-select>
                        </div>
                        <div
                          v-for="error in errors.volume_limit_volume"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >Pallet Selection Limits
                        </label>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroup-sizing-default"
                                >Min</span
                              >
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              :placeholder="'Min Pallet'"
                              v-model="forms.pallet_limit_min"
                            />
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroup-sizing-default"
                                >Max</span
                              >
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              :placeholder="'Max Pallet'"
                              v-model="forms.pallet_limit_max"
                            />
                          </div>
                        </div>
                        <div
                          v-for="error in errors.pallet_limit_max"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >Pieces Selection Limits
                        </label>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroup-sizing-default"
                                >Min</span
                              >
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              :placeholder="'Min Pieces'"
                              v-model="forms.pieces_limit_min"
                            />
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroup-sizing-default"
                                >Max</span
                              >
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              :placeholder="'Max Pieces'"
                              v-model="forms.pieces_limit_max"
                            />
                          </div>
                        </div>
                        <div
                          v-for="error in errors.pieces_limit_max"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <h6>Fixed Fees</h6>
                    </div>
                    <div class="card-body">
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >Fixed Fee per Shipment
                        </label>
                        <div class="col-sm-3">
                          <div class="input-group">
                            <input
                              type="number"
                              class="form-control"
                              v-model="forms.fixed_shipment_idr"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">IDR</span>
                            </div>
                          </div>
                        </div>
                        <div
                          v-for="error in errors.fixed_shipment_idr"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >Fixed Fee per Shipment
                        </label>
                        <div class="col-sm-3">
                          <div class="input-group">
                            <input
                              type="number"
                              class="form-control"
                              v-model="forms.fixed_shipment_usd"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">USD</span>
                            </div>
                          </div>
                        </div>
                        <div
                          v-for="error in errors.fixed_shipment_usd"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >Fixed Fee per Load
                        </label>
                        <div class="col-sm-3">
                          <div class="input-group">
                            <input
                              type="number"
                              class="form-control"
                              v-model="forms.fixed_load_idr"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">IDR</span>
                            </div>
                          </div>
                        </div>
                        <div
                          v-for="error in errors.fixed_load_idr"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                      <div class="form-group row mb-2">
                        <label class="form-label col-sm-3"
                          >Fixed Fee per Load
                        </label>
                        <div class="col-sm-3">
                          <div class="input-group">
                            <input
                              type="number"
                              class="form-control"
                              v-model="forms.fixed_load_usd"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">USD</span>
                            </div>
                          </div>
                        </div>
                        <div
                          v-for="error in errors.fixed_load_usd"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="card-footer">
                    <button
                      class="btn btn-warning text-black fw-bolder btn-icon-text"
                      @click="backForm"
                      type="button"
                    >
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>

                    <a
                    v-if="!isLoading"
                      class="btn btn-success me-2 text-white fw-bolder btn-icon-text float-end"
                      @click="submitData()"
                    >
                      <i class="link-icon" data-feather="save"></i>
                      {{ $t("submitOrderTxt") }}
                    </a>

                    <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>

                  </div>
            </div>
          </div>
        </div>
        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
export default {
  name: "BillableMethodAdd",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      isLoading:false,
      company_id:'',
      weight:'',
      volume:'',
      billable_method_rate:'',
      default_alocation_method:'',
      rating_method:'',
      billableTariff:'',

      fetchWeight:[],
      billable_tarif:[],
      fetchVolume:[],
      fetchDefaulAlocationMethod:[],
      fetchBillableTariff:[],
      fetchBillableMethodDateRate:[],
      fetchratingmethod:[],
    
      permision_role: [],
 
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      forms: {},
      company: '',
      fetchCompany:[],
    };
  },
  methods: {
    backForm() {
        if(this.company_id!=''){
          window.location.href = "/billable-method?company_id="+this.$route.query.company_id;
        }else{
          window.location.href = "/billable-method";
        }
    },

    


    loadData(id) {
      const baseURI =
        this.$settings.endPoint + "rm-billable-method/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        // console.log(response);
        this.forms = response.data.datas;

      
      this.company={
        'company_id':this.forms.company!=null ? this.forms.company.company_id : '',
        'name':this.forms.company!=null ? this.forms.company.name : '' 
      };

      this.weight={
        'value':this.forms.weight_limit_weight
      };

      this.volume={
        'value':this.forms.volume_limit_volume
      }
      this.billable_method_rate={
        'value':this.forms.date_rate
      }
      this.default_alocation_method={
        'value':this.forms.default_method
      }
      this.rating_method={
        'value':this.forms.rating_method
      }
      this.billableTariff={
        'tariff_name': this.forms.billable_tariff
      }
        
        // this.company = response.data.datas;

        // this.detailAll = this.forms.transport_order_detail_template;

        // this.form={ name: 'asep', usernmae:res.username };
      });
    },


    loadCompany() {
      const baseURI = this.$settings.endPoint + "company";
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchCompany=response.data.datas.data;

        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              // if(error.response.status === 422) {
              //   this.errors = error.response.data.errors;
              //   this.resultError(error.response.data.errors);
              // }else if (error.response.status === 500) {
              //     this.$router.push('/server-error');
              // }else if (error.response.status === 401) {
              //     this.$router.push("/authorized-error");
              // }else{
              //     this.$router.push('/page-not-found');
              // }
            }

          }

        });
    },


    fade(sType) {
      this.isLoading = sType;
    },

    // loading() {
    //   this.fade(true);
    //   setTimeout(() => {
    //     this.fade(false);
    //   }, 1000); // hide the message after 3 seconds
    // },


    loadBillableTariff(ev) {
      var params='';
      if(ev!='all'){
        params='?customer_code='+ev;
      }

      const baseURI =
        this.$settings.endPoint +
        `rm-billable-tariff`+params;
      return this.$http.get(baseURI).then((response) => {
        this.fetchBillableTariff = response.data.datas.data;
      });
    },


    asyncSearchBillableTariff(ev){
      this.fetchBillableTariff=[];
      const baseURI =
        this.$settings.endPoint +
        `rm-billable-tariff?customer_code=${ev}&tarif_name=`+ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchBillableTariff = response.data.datas.data;
      });
    },


    loadRatingMethod() {
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=bmrm";
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchratingmethod=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },



    loadDefaultAlocationMethod() {
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=bmdam";
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchDefaulAlocationMethod=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },




    loadBillableWeight() {
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=bmwsl";
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchWeight=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },

    asyncloadBillableWeight(ev) {
      this.fetchWeight=[];
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=bmwsl&value="+ev;
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchWeight=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },



    loadBillableVolume() {
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=bmvsl";
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchVolume=response.data.datas.data;
            console.log(this.fetchWeight);
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },

    asyncloadBillableVolume(ev) {
      this.fetchVolume=[];
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=bmvsl&value="+ev;
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchVolume=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },


    asyncloadDefaultAlocationMethod(ev) {
      this.fetchDefaulAlocationMethod=[];
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=bmdam&value="+ev;
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchDefaulAlocationMethod=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },



    loadBillableMethodDateRate() {
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=bmdr";
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchBillableMethodDateRate=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },


    asyncloadBillableMethodeDateRate(ev) {
      this.fetchBillableMethodDateRate=[];
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=bmdr&value="+ev;
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchBillableMethodDateRate=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },


    


    asyncloadRatingMethod(ev) {
      this.fetchratingmethod=[];
      const baseURI = this.$settings.endPointCt + "master-lookup/index-view?unique_id=bmrm&value="+ev;
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchratingmethod=response.data.datas.data;
        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }
            }

          }

        });
    },


    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          setTimeout(() => {
            if(this.company_id==null || this.company_id=='' || this.company_id==undefined){
              window.location.href = '/billable-method';
            }else{
              var params = this.$onRandom(this.company_id);
              window.location.href = '/billable-method?company_id='+params;
            }
          }, 3000);
        },
       
      });
      Toast.fire({
        icon: 'success',
        title: kata
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton:false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          
        },
       
      });
      Toast.fire({
        icon: 'failed',
        title: kata
      });
    },
  

    submitData() {

      this.forms.company_id = this.company.company_id;

      this.forms.weight_limit_weight=this.weight.value;

      this.forms.volume_limit_volume=this.volume.value;
      this.forms.date_rate=this.billable_method_rate.value;

      this.forms.default_method=this.default_alocation_method.value;
      this.forms.sob='NEW';
      this.forms.rating_method=this.rating_method.value
      this.forms.billable_tariff=this.billableTariff.tariff_name;

      this.fade(true);
      const baseURI = this.$settings.endPoint + "rm-billable-method/update/"+this.forms.rm_billable_method_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === 200) {
            
              this.success('Success, update billable method. Redirecting in 3 seconds...');

             
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }

            //this.resetForm();
            // this.errors = [];
            // //console.log(response.data.datas.user_id);
            // var params = this.$onRandom(response.data.datas.package_id);
            //window.location.href = "/billable-method";
            // // this.success('Berhasil');
          } else {
            this.fade(false);
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.fade(false);
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },
    // backForm(){
    //   window.location.href = "/billable-method";
    // },


    asyncSearchCompany(ev) {
      this.fetchCompany=[];
      const baseURI = this.$settings.endPoint + "company?name="+ev;
      //this.isLoading = true;
     
      return this.$http
        .get(
          baseURI,{timeout: 10000 }
        )
        .then((response) => {
            this.fetchCompany=response.data.datas.data;

        }).catch(error => {
          //this.isLoading = false;
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }

        });
    },
   

  },
  mounted() {
    var params = this.$onBehind(this.$route.params.id);

    this.company_id=this.$route.query==undefined ? '' : this.$onBehind(this.$route.query.company_id);
    this.loadData(params[0]);

    this.loadCompany();
    this.loadRatingMethod();
    this.loadDefaultAlocationMethod()
    this.loadBillableMethodDateRate();
    this.loadBillableWeight();
    this.loadBillableVolume();
    this.loadBillableTariff('all');
  },
  
};
</script>

<style>
</style>