import { render, staticRenderFns } from "./TolRouteDetail.vue?vue&type=template&id=1bf4f274&scoped=true&"
import script from "./TolRouteDetail.vue?vue&type=script&lang=js&"
export * from "./TolRouteDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bf4f274",
  null
  
)

export default component.exports