<template>
  <input ref="inputRef" type="text" />
</template>

<script>
import { watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    value: Number,
    options: Object,
  },
  setup(props) {
    const { inputRef, setValue } = useCurrencyInput(props.options);
    // console.log(props.options);
    // console.log(props);
    watch(
      () => props.value, // Vue 2: props.value
      (value) => {
        setValue(value);
        console.log(value);
      }
    );
    return { inputRef };
  },
};
</script>
