<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TolRoute"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">
                    Rute Tol
                  </h4>
                  <button
                    v-if="permision_role.method_create"
                    class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="createData()"
                  >
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>

                  <button
                    class="btn btn-warning float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="uploadData()"
                  >
                    <i class="link-icon" data-feather="upload"></i>
                    {{ $t("uploadData") }} .xlsx
                  </button>

                  <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                </div>

                <div class="card-body">
                  <vue-good-table
                    title="list-parties"
                    ref="list-parties"
                    mode="remote"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: false,
                      selectionText: $t('data_selected'),
                      clearSelectionText: $t('clear'),
                    }"
                    @on-selected-rows-change="selectionChanged"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :line-numbers="true"
                    :isLoading.sync="isLoading"
                    :pagination-options="{
                      enabled: true,
                    }"
                    :rows="rows"
                    :columns="columns"
                  >

                  <div slot="table-actions">
                      {{ $t("no_select_data") }}
                    </div>
                    <div slot="selected-row-actions">
                      <button
                        v-if="rowsSelection.length==1"
                        class="btn btn-success btn-xs"
                        style="margin-right: 20px"
                        @click="calculation"
                      >
                        <i class="mdi mdi-calculator" ></i>Kalkulasi
                      </button>
                      <span> </span>
                    </div>

                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <a
                          href="#"
                          v-if="permision_role.method_read"
                          title="Detail"
                          @click.prevent="detailData(props.index, props.row)"
                        >
                          <i class="mdi mdi-eye" style="font-size: 16px"></i>
                        </a>
                        <a
                          href="#"
                          v-if="permision_role.method_update"
                          title="Edit"
                          @click.prevent="editData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-square-edit-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>

                        <a
                          href="#"
                          v-if="permision_role.method_delete"
                          title="Delete"
                          @click.prevent="deleteData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-trash-can-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>
                      </span>
                      <span v-else-if="props.column.field == 'tol_start'">
                        {{ getTol(props.row.departure) }}
                      </span>
                      <span v-else-if="props.column.field == 'tol_end'">
                        {{ getTol(props.row.back) }}
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "TolRoute",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      rowsSelection:[],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Kode",
          field: "toll_route_code",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Code ", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Nama",
          field: "name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tol Berangkat",
          field: "tol_start",
          // filterOptions: {
          //   enabled: true, // enable filter for this column
          //   placeholder: "Filter By Sku Long Description", // placeholder for filter input
          //   filterValue: "", // initial populated value for this filter
          //   filterDropdownItems: [], // dropdown (with selected values) instead of text input
          //   trigger: "enter", //only trigger on enter not on keyup
          // },
        },

        {
          label: "Tol Pulang",
          field: "tol_end",
          // filterOptions: {
          //   enabled: true, // enable filter for this column
          //   placeholder: "Filter By Barcode ", // placeholder for filter input
          //   filterValue: "", // initial populated value for this filter
          //   filterDropdownItems: [], // dropdown (with selected values) instead of text input
          //   trigger: "enter", //only trigger on enter not on keyup
          // },
        },

        {
          label: "Calc Date",
          field: "date_calculation",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter Date Calculation", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },


        {
          label: "Calc Status",
          field: "flag_success",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Calc Rekon",
          field: "kalkulasi_rekon",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  watch: {},
  methods: {
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmTollRouteController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;
    },

    downloadData() {
      const baseURI = this.$settings.endPoint + "/rm-toll-route";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&toll_route_code=${this.serverParams.columnFilters.toll_route_code}&name=${this.serverParams.columnFilters.name}&tol_start=${this.serverParams.columnFilters.tol_start}&tol_end=${this.serverParams.columnFilters.tol_end}&date_calculation=${this.serverParams.columnFilters.date_calcultation}&flag_status=${this.serverParams.columnFilters.flag_status}&kalkulasi_rekon=${this.serverParams.columnFilters.kalkulasi_rekon}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    createData() {
      window.location.href = "/tol-route/add";
    },

    uploadData() {
      window.location.href = "/tol-route/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.rm_toll_route_id);
      window.location.href = "/tol-route/edit/" + params;
    },

    selectionChanged(ev) {
      this.rowsSelection = [];

      this.rowsSelection = ev.selectedRows;
     
    
      // this.rm_freight_billable_parties_ids = [];
      // this.rowsSelection.forEach((item) => {
      //   this.rm_freight_billable_parties_ids.push(
      //     item.rm_freight_billable_parties_id
      //   );
      // });
    },

    detailData(index, row) {
      var params = this.$onRandom(row.rm_toll_route_id);
      window.location.href = "/tol-route/detail/" + params;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint +
            "rm-toll-route/delete/" +
            row.rm_toll_route_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPoint + "rm-toll-route";

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&toll_route_code=${this.serverParams.columnFilters.toll_route_code}&name=${this.serverParams.columnFilters.name}&tol_start=${this.serverParams.columnFilters.tol_start}&tol_end=${this.serverParams.columnFilters.tol_end}&date_calculation=${this.serverParams.columnFilters.date_calcultation}&flag_status=${this.serverParams.columnFilters.flag_status}&kalkulasi_rekon=${this.serverParams.columnFilters.kalkulasi_rekon}`
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });
    },
    getTol(ev) {
      let location = "";
      ev.forEach(function (gate, index) {
        if (index == 0) {
          location += gate.toll_gate_name;
        } else {
          location += " - " + gate.toll_gate_name;
        }
      });
      return location;
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

        onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },



    calculation(){
      this.$swal({
        title: 'Apakah anda yakin akan mengkalkulasi Toll Route ?',
        text: '',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
        html: `
        <p>Semua record Payable akan mengalamin kenaikan sesuai dengan exponensial kenaikan harga toll Route ini</p><br>
                        <input type="checkbox" id="kalkulasiRekon" >
                        <label for="kalkulasiRekon">Kalkulasi Rekon</label>
                    `,
      }).then((result) => {
        if (result.value) {

          const checkbox = document.getElementById('kalkulasiRekon');
          var rekon = checkbox.checked;

          // var formData = {
          //   status: status,
          // };
          const baseURI =
            this.$settings.endPoint +
            "rm-toll-route/calculation/"+this.rowsSelection[0].rm_toll_route_id
          this.$http
            .post(baseURI,{ "rekon" : rekon==true ? 'yes': 'no', })
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },


  

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loading();
  },
};
</script>
<style scoped></style>
