const settings = {
  appName: process.env.VUE_APP_NAME,
  endPoint: process.env.VUE_APP_ENPOINT_BACKEND,
  endPointCt: process.env.VUE_APP_ENPOINT_BACKEND_CT,
  endPointFP: process.env.VUE_APP_ENPOINT_BACKEND_FP,
  endPointGT: process.env.VUE_APP_ENPOINT_BACKEND_GT,
  endPointFppk: process.env.VUE_APP_ENPOINT_BACKEND_FPPK,
  endPointApp: process.env.VUE_APP_ENPOINT_APP,
  endPointMiddleware: process.env.VUE_APP_ENPOINT_MIDDLEWARE,
  appType: process.env.VUE_APP_ENV,
  jwtSecret: process.env.VUE_APP_JWT_SECRET,
}

export default settings;