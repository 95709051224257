<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TolRouteDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Detail Rute Penyeberangan
                  </h6>
                  <button
                    class="btn btn-light float-end"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div
                    class="row mb-3 collapse"
                    id="collapseExample"
                    style="padding-left: 20px"
                  >
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.create_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.update_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Nama Rute Penyebrangan</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="nama"
                          :placeholder="'Nama Rute Tol'"
                          v-model="forms.nama"
                          readonly
                        />
                        <div v-if="errors.nama">
                          <div
                            v-for="error in errors.nama"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Type Truck</label
                        >
                      </div>
                      <div class="col-lg-8">
                         <input
                          type="text"
                          class="form-control"
                          id="nama"
                          :placeholder="'Nama Rute Tol'"
                          v-model="forms.type_truck"
                          readonly
                        />
                        <div v-if="errors.type_truck">
                          <div
                            v-for="error in errors.type_truck"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Rute</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="nama"
                          :placeholder="'Nama Rute Tol'"
                          v-model="forms.rute"
                          readonly
                        />
                        <div v-if="errors.rute">
                          <div
                            v-for="error in errors.rute"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Penyebrangan</label
                        >
                      </div>
                      <div class="col-lg-8">
                         <input
                          type="text"
                          class="form-control"
                          id="nama"
                          :placeholder="'Nama Rute Tol'"
                          v-model="forms.penyeberangan"
                          readonly
                        />
                        <div v-if="errors.penyeberangan">
                          <div
                            v-for="error in errors.penyeberangan"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Total Biaya</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          v-model="forms.total_biaya "
                        />
                        <div v-if="errors.total_biaya">
                          <div
                            v-for="error in errors.total_biaya"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-6">
                        <div class="card">
                          <div class="card-header">
                            <h6>Rute</h6>
                            <button
                              class="btn btn-success btn-sm float-end"
                              @click="addData(route)"
                              type="button"
                              disabled
                            >
                              <i class="link-icon" data-feather="plus"></i>
                              Add
                            </button>
                          </div>
                          <div class="card-boy">
                            <div class="row">
                              <div class="table-responsive">
                                <table
                                  class="table table-bordered mb-3"
                                >
                                  <thead>
                                    <tr>
                                      <td>Rute</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in route"
                                      :key="index"
                                    >
                                      <td>
                                        {{item.rute}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="card">
                          <div class="card-header">
                            <h6>Penyebrangan</h6>
                            <button
                              class="btn btn-success btn-sm float-end"
                              @click="addData(penyebrangan)"
                              type="button"
                              disabled
                            >
                              <i class="link-icon" data-feather="plus"></i>
                              Add
                            </button>
                          </div>
                          <div class="card-boy">
                            <div class="row">
                              <div class="table-responsive">
                                <table
                                  class="table table-bordered mb-3"
                                >
                                  <thead>
                                    <tr>
                                      <td>Nama Penyebrangan</td>
                                      <td>Harga</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in penyebrangan"
                                      :key="index"
                                    >
                                      <td>
                                        {{item.nama}}
                                      </td>
                                      <td>
                                        Rp.
                                        {{
                                          item.harga
                                            ? item.harga
                                            : 0 | format_number
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                     <a
                        v-if="permision_role.method_delete"
                        class="btn btn-danger text-white fw-bolder btn-icon-text float-end"
                        @click="deleteData()"
                      >
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a
                        v-if="permision_role.method_update"
                        class="btn btn-primary me-2 text-white fw-bolder btn-icon-text float-end"
                        @click="editData()"
                      >
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>


                      <span
                      
                        class="btn btn-success float-end "
                        style="margin-right: 5px"
                     
                        @click="calculate()"
                      >
                        <i class="mdi mdi-calculator" style="font-size:16px"></i>
                        Calculate
                      </span>


                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "SkuDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      rekon:false,
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      fetchCompany: [],
      fetchPackage: [],
      fetchCommodity: [],
      userData: "",
      companyCode: "",
      forms: [],
      penyebrangan:[],
      route:[],
    };
  },
  watch: {},
  methods: {
    resetForm() {},

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/master-rute-penyebrangan/edit/" + params;
    },


    calculate() {
      this.$swal({
        title: "Apakah Anda Yakin akan mengkalkulasi Master Rute Penyebrangan ini ?",
        text: "",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
        html: `<p>Semua Record Payable akan mengalami kenaikan sesuai dengan exponensial kenaikan biaya rute penyebrangan ini</p><br>
                        <input type="checkbox" id="kalkulasiRekon" >
                        <label for="kalkulasiRekon">Kalkulasi Rekon</label>
                    `,
      }).then((result) => {
       
        const checkbox = document.getElementById('kalkulasiRekon');
        this.rekon = checkbox.checked;
                

        if (result.isConfirmed) {

         

          this.fade(true);
          
          // var encode = this.$onRandom(this.forms.rm_ferry_master_id);
        const rm_ferry_rute_ids=[];
        const baseURI = this.$settings.endPoint +"rm-ferry-rute/calculation-mass";
        
        if(this.penyebrangan.length > 0){
          for (let index = 0; index < this.penyebrangan.length; index++) {
            rm_ferry_rute_ids.push(this.penyebrangan[index].rm_ferry_rute_id);
          }
        }

        this.$http
          .post(baseURI,{
                "rm_ferry_rute_ids":rm_ferry_rute_ids,
                "rekon" : this.rekon==true ? 'yes': 'no', // yes or no
               
          })
          .then((response) => {
            this.loading();
            if (response.data.status === 200) {
              //this.resetForm();
              this.errors = [];
              
              var payload=response.data.datas.messages;
     
            
              var payloadSend={
                messages : []
              }

              if(payload.length > 0){
                console.log(payload);
                var windows=window.location.origin;
                payload.forEach((res) => {
                  var dataSend={
                      "rm_ferry_rute_id": res?.rm_ferry_rute_id,
                      "RmUjoMaster": res?.RmUjoMaster,
                      "RmPayableAccessorial":  res?.RmPayableAccessorial,
                      "RmPayableRateDetailLog": res?.RmPayableRateDetailLog,
                      "RmPayableAccessorialLog": res?.RmPayableAccessorialLog,
                      "rm_payable_calculation_id": res?.rm_payable_calculation_id,
                      "nama": res?.name,
                      "type_truck": res?.type_truck,
                      "calculation_name": res?.calculation_name,
                      "rekon": res?.rekon,
                      "link_page_pay_rate_archiving_detail_id": windows+"/payable-rate/archiving/detail/"+this.$onRandom(res?.rm_payable_calculation_id)
                  }

                  
                  payloadSend.messages.push(dataSend);
                })
               
                console.log(payloadSend);
                this.displayResponseTable(response.data.datas.messages);
                this.sendMail(payloadSend);
              }
                    
              
            } else {
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                // this.resultError(error.response.data.errors);
                if (this.errors.message) {
                  this.error(this.errors.message);
                } else {
                  this.resultError(error.response.data.errors);
                }
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });
        }

     
        
      });
    },


    displayResponseTable(messages) {
      const allowedKeys = [
        "rm_ferry_rute_id",
        "Update RmUjoMaster ",
        "Update RmPayableAccessorial ",
        "Create RmPayableRateDetailLog ",
        "Create RmPayableAccessorialLog ",
      ];

      const tableContent = messages.map((message) => {
        const filteredEntries = Object.entries(message)
          .filter(([key]) => allowedKeys.includes(key))
          .map(([key, value]) => `<tr><td>${key}</td><td>${value}</td></tr>`);

        return `
          <table class="table">
            <thead>
              <tr>
                <th>Pesan</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              ${filteredEntries.join('')}
            </tbody>
          </table>
        `;
      });

      this.$swal({
        title: "Notification",
        html: tableContent.join(''),
        showCloseButton: true,
        showConfirmButton: false,
      });
    },





    sendMail(payload) {
   
      const baseURI = this.$settings.endPoint + "rm-ferry-rute/calculation-mass-send-email";
          this.$http
            .post(baseURI, payload)
            .then((response) => {
              console.log(response)
              // this.success(response.data);
              var params = this.$onBehind(this.$route.params.id);
              this.loadData(params)

             
            })
            .catch((error) => {
              //this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          const baseURI =
            this.$settings.endPoint + "rm-ferry-rute/delete/" + id;
          this.$http
            .delete(baseURI)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/master-rute-penyebrangan";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    backForm() {
      window.location.href = "/master-rute-penyebrangan";
    },

    loadData(id) {
      const baseURI = this.$settings.endPoint + "rm-ferry-rute/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.route = response.data.datas.detail;
        this.penyebrangan = response.data.datas.ferry_master;
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmFerryRuteController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped></style>
