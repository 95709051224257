<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BbmIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <div class="row mb-3">
                    <h4 style="margin-right: 5px" class="float-start mb-3">
                      Detail - Payable SL (Rute)
                    </h4>
                    <div class="col-sm-4">
                      <!-- <div class="row">
                        <table class="table table-borderless">
                          <tr>
                            <th width="10px">Costumer Code</th>
                            <td width="5px">:</td>
                            <td>{{ header.customer_code }}</td>
                          </tr>
                          <tr>
                            <th width="10px">Costumer Name</th>
                            <td width="5px">:</td>
                            <td>{{ header.customer_name }}</td>
                          </tr>
                          <tr>
                            <th width="10px">SAP Code</th>
                            <td width="5px">:</td>
                            <td>{{ header.sap_code }}</td>
                          </tr>
                        </table>
                      </div> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="mb-3">
                        <label for="exampleInputUsername1" class="form-label"
                          >Carrier</label
                        >
                        <!-- <v-select
                          :options="fetchCarier"
                          v-model="search.carier"
                          return-object
                        ></v-select> -->

                        <multiselect @search-change="asyncCarrier" v-model="search.carier" :options="fetchCarier"
                             label="carrier_name" track-by="carrier_code"
                            placeholder="Please Select">
                            <span slot="noResult">-</span>
                        </multiselect>

                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-3">
                        <label for="exampleInputUsername1" class="form-label"
                          >Origin</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="search.origin"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-3">
                        <label for="exampleInputUsername1" class="form-label"
                          >Destination</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="search.destination"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-3">
                        <label for="exampleInputUsername1" class="form-label"
                          >Type SL</label
                        >
                        <v-select
                          :options="fetchTypeSl"
                          v-model="search.type_sl"
                          label="label"
                          :reduce="data => data.value"
                          return-object
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-lg-1">
                      <div class="mb-3">
                        <label class="form-label">Status</label>
                        <div class="mb-4">
                          <div class="form-check mb-2">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="future"
                              value="future"
                              name="future"
                              v-model="search.status"
                            />
                            <label class="form-check-label" for="future">
                              Future
                            </label>
                          </div>
                          <div class="form-check mb-2">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="current"
                              value="current"
                              name="current"
                              v-model="search.status"
                            />
                            <label class="form-check-label" for="current">
                              Current
                            </label>
                          </div>
                          <div class="form-check mb-2">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="expired"
                              value="expired"
                              name="expired"
                              v-model="search.status"
                            />
                            <label class="form-check-label" for="expired">
                              Expired
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-1">
                      <div class="mb-3">
                        <button
                          class="btn btn-primary"
                          type="button"
                          @click="searchData"
                        >
                          Search
                        </button>
                      </div>
                    </div>

                    <div class="col-lg-1">
                      <div class="mb-3">
                        <button
                          class="btn btn-danger"
                          type="button"
                          @click="resetFilter"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    v-if="permision_role.method_upload"
                    class="btn btn-warning float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="uploadData()"
                  >
                    <i class="link-icon" data-feather="upload"></i>
                    {{ $t("uploadData") }} .xlsx
                  </button>
                  <button
                    v-if="permision_role.method_read"
                    class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="downloadData()"
                  >
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                  <button
                    v-if="permision_role.method_create"
                    class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px"
                    @click.prevent="createData()"
                  >
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button>
                </div>

                <div class="card-body">
                  <vue-good-table title="list-user" mode="remote" @on-selected-rows-change="selectionChanged"
                    @on-per-page-change="onPerPageChange" @on-sort-change="onSortChange" @on-page-change="onPageChange"
                    :totalRows="totalRecords" :line-numbers="true" :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true,
                      selectionText: $t('data_selected'),
                      clearSelectionText: $t('clear'),
                    }" :isLoading.sync="isLoading" :pagination-options="{
                        enabled: true,
                      }" :rows="rows" :columns="columns">


                    <div slot="table-actions">
                      {{ $t("no_select_data") }}
                    </div>
                    <div slot="selected-row-actions">
                      <button class="btn btn-success btn-xs" style="margin-right: 20px" @click="sendToBlujay">
                        <i class="mdi mdi-send-outline"></i>Send To Blujay
                      </button>
                      <span> </span>
                    </div>

                    <template slot="table-row" slot-scope="props">
                      
                      <span v-if="props.column.field == 'actions'">
                        <a
                          href="#"
                          v-if="permision_role.method_read"
                          title="Detail"
                          @click.prevent="detailData(props.index, props.row)"
                        >
                          <i class="mdi mdi-eye" style="font-size: 16px"></i>
                        </a>

                       
                        <a
                          href="#"
                          v-if="props.row.status_rekomendasi=='Yes' && props.row.carrier_name=='VENDOR' || props.row.status_rekomendasi=='Yes' && props.row.carrier_name=='OWN'"
                          title="Rekomendasi"
                          @click.prevent="viewRekomendasi(props.row)"
                        >
                          <i
                            class="mdi mdi-file-find"
                            style="font-size: 16px"
                          ></i>
                        </a>
                        <a
                          href="#"
                          v-if="permision_role.method_update"
                          title="Edit"
                          @click.prevent="editData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-square-edit-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>

                        <a
                            href="#"
                            title="Copy"
                            @click.prevent="copyData(props.index, props.row)"
                          >
                            <i
                              class="mdi mdi-content-copy"
                              style="font-size: 16px"
                            ></i>
                          </a>

                        <a
                          href="#"
                          v-if="permision_role.method_delete"
                          title="Delete"
                          @click.prevent="deleteData(props.index, props.row)"
                        >
                          <i
                            class="mdi mdi-trash-can-outline"
                            style="font-size: 16px"
                          ></i>
                        </a>

                        
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>


                  <div class="card-footer">
                      <!-- <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button> -->
                      
                  </div>




              </div>

              <modal-rekomendasi
              :showModal="showModalRekomendasi"
              filterBy="rute"
              :data="rm_payable_rate_detail_id"
              @closeModal="closeMyModal"
            ></modal-rekomendasi>
            
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalRekomendasi from "@/components/modal/modalPayableRekomendasi.vue";
import multiselect from "vue-multiselect";

export default {
  name: "PayableRateDetail",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-rekomendasi":modalRekomendasi,
    multiselect:multiselect
  },
  data() {
    return {
      rm_payable_rate_detail_id:null,
      showModalRekomendasi:false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      splittedText: [],
      header: {},
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      fetchCarier: [],
      filter:{},
      search: {
        carier:"",
        origin:"",
        destination:"",
        type_sl:"",
        status:[],
      },
      fetchTypeSl: [
        {
          label: "Type A / Region",
          value: "A",
        },
        {
          label: "Type B / SL",
          value: "B",
        },
      ],

      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: "Action",
          field: "actions",
          sortable: false,
          width:"100px",
        },
        {
          label: "Enabled",
          field: "is_enabled",
        },
        {
          label: "Status",
          field: "status_rekomendasi",
        },
        {
          label: "Equipment",
          field: "equipment",
        },
        {
          label: "Carrier",
          field: "carrier_name",
        },
        {
          label: "Origin Location",
          field: "origin_location",
        },
        {
          label: "Origin Region",
          field: "origin_region",
        },
        {
          label: "Destination Location",
          field: "destination_location",
        },
        {
          label: "Destination Region",
          field: "destination_region",
        },
        {
          label: "Effective Date",
          field: "effective_date",
        },
        {
          label: "Expiration Date",
          field: "expiration_date",
        },
        {
          label: "Currency",
          field: "currency",
        },
        {
          label: "Basis",
          field: "km_basis",
        },
        {
          label: "Rate",
          field: "km_margin",
        },
        {
          label: "Penyebrangan",
          field: "penyebrangan",
        },
        {
          label: "Deposit",
          field: "deposit",
        },
        {
          label: "Self Insurance",
          field: "self_insurance",
        },
        {
          label: "Lead Time",
          field: "lead_time",
        },
        {
          label: "Tol",
          field: "tol",
        },
        {
          label: "Komisi",
          field: "komisi",
        },
        {
          label: "Mel",
          field: "mel",
        },
      ],
      rows: [],
      rm_payable_rate_detail_ids: [],
    };
  },
  watch: {},
  methods: {

    selectionChanged(ev) {
      this.rowsSelection = [];

      this.rowsSelection = ev.selectedRows;

      this.rm_payable_rate_detail_ids = [];
      this.rowsSelection.forEach((item) => {
        this.rm_payable_rate_detail_ids.push(
          item.rm_payable_rate_detail_id
        );
      });

     
    },

    loadCarier() {
      const baseURI =
        this.$settings.endPointCt + `master-carrier`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCarier = response.data.datas.data;
      });
    },

    asyncCarrier(ev) {
      const baseURI =
        this.$settings.endPointCt +
        "master-carrier?carrier_name=" +ev;
      this.fetchCarier= [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchCarier = response.data.datas.data;
      });
    },


    downloadData() {
      const baseURI = this.$settings.endPoint + "/rm-fuel-master";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&period_code=${this.serverParams.columnFilters.period_code}&period_name=${this.serverParams.columnFilters.period_name}&price=${this.serverParams.columnFilters.price}&price_gap=${this.serverParams.columnFilters.price_gap}&effective_date=${this.serverParams.columnFilters.effective_date}&expired_date=${this.serverParams.columnFilters.expired_date}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },
    searchData() {
      this.filter = {};
    
      var c=this.search.carier.carrier_name;
      if (c!='') {
        this.splittedText = c.split("-"); // Memisahkan teks berdasarkan karakter '-'
      } else {
        console.error('Text is undefined or empty!');
      }
      // console.log(this.splittedText);
      this.filter = {
            carrier_name:this.search.carier==undefined ? '' :  this.splittedText[1],
            origin_location: this.search.origin,
            destination_location: this.search.destination,
            status: this.search.status,
            type_sl: this.search.type_sl,
      };
      console.log(this.filter);
      this.loadItems();
    },

    resetFilter(){
      this.search=[]
      this.filter = {
            carrier_name:'',
            origin_location: '',
            destination_location: '',
            status: '',
            type_sl: ''
      };
  
      this.loadItems();
    },

    setCookies(name, data) {
      let path = this.$route.path;
      let d = new Date();
      d.setDate(d.getDate() + 1);
      let exp = "expires=" + d.toUTCString();
      document.cookie = name + "=" + data + ";" + exp + ";path=" + path;
      // console.log(data);
    },

    copyData(index, row) {
      this.fade(true);
      var params = this.$onRandom(row.rm_payable_rate_detail_id);
      window.location.href ="/payable-rate/rute/add?detailId="+params;
    },


    applyCookieFilter() {
      let cookies = document.cookie;
      if (cookies) {
        cookies = cookies.split("; ");
        let objCookies = {};
        cookies.forEach((item) => {
          let i = item.split("=");
          var data = "";
          if (i[0] == "filter") {
            data = JSON.parse(i[1]);
          } else {
            data = i[1];
          }
          objCookies[i[0]] = data;
        });

        this.search = objCookies.filter;
        this.searchData();
        // console.log("Cookies Implement Complete");
      } else {
        // console.log("Fresh Page No Cookies");
      }
      // console.log(JSON.parse(cookies));
    },

    closeMyModal(){
      this.showModalRekomendasi=false
    },

    backForm(){
      window.location.href =
        "/payable-rate/rute";
    },

    viewRekomendasi(data){
      // console.log(index);
      this.rm_payable_rate_detail_id=data.rm_payable_rate_detail_id;
      this.showModalRekomendasi=true;
    },
    // downloadData() {
    //   this.fade(true);
    //   var baseURI = this.$settings.endPoint + "/user/download";
    //   var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
    //   var columnFilters = this.serverParams.columnFilters;
    //   var role = columnFilters["role.name"];
    //   var region_code = columnFilters["region_detail.code"];

    //   var sendData = {
    //     username: this.serverParams.columnFilters.username,
    //     name: this.serverParams.columnFilters.name,
    //     email: this.serverParams.columnFilters.email,
    //     level: this.serverParams.columnFilters.level,
    //     status: this.serverParams.columnFilters.status,
    //     role:role,
    //     region_code: region_code,
    //     file_name: "download_" + CurrentDate + ".xlsx",
    //   };
    //   this.$http({
    //     url: baseURI,
    //     method: "GET",
    //     data: sendData,
    //     responseType: "blob",
    //   })
    //     .then((response) => {
    //       this.errors = [];
    //       var filename = sendData.file_name;

    //       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //       var fileLink = document.createElement("a");

    //       fileLink.href = fileURL;
    //       fileLink.setAttribute("download", filename);
    //       document.body.appendChild(fileLink);
    //       fileLink.click();
    //       this.fade(false);
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.status === 422) {
    //           this.errors = { message: ["File Not Found"] };
    //           this.error("File Not Found");
    //         } else if (error.response.status === 500) {
    //           this.$router.push("/server-error");
    //         } else {
    //           this.$router.push("/page-not-found");
    //         }
    //       }
    //     });
    // },

    sendToBlujay() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {

            rm_payable_rate_detail_ids: this.rm_payable_rate_detail_ids,
          }
          const baseURI =
            this.$settings.endPoint + "rm-payable-rate-header/send-to-blujay-nintex";
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });



    },

    createData() {
      window.location.href ="/payable-rate/rute/add";
    },

    uploadData() { 
      window.location.href = "/master-bbm/upload";
    },

    editData(index, row) {
      var params = this.$onRandom(row.rm_payable_rate_detail_id);
      window.location.href = "/payable-rate/rute/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.rm_payable_rate_detail_id);
      window.location.href = "/payable-rate/rute/detail/" + params;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint +
            "rm-payable-rate-header/delete-detail/" +
            row.rm_payable_rate_detail_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.loading();
      // console.log(this.filter.carrier);
      //var params = this.$onBehind(this.$route.params.id);
      const baseURI =
        this.$settings.endPoint + "rm-payable-rate-header/detail-rate-rute";
      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&origin_location=${this.filter?.origin_location ? this.filter?.origin_location : ""}&destination_location=${this.filter?.destination_location ? this.filter?.destination_location : ""}&carrier_name=${this.filter?.carrier_name!=undefined ? this.filter?.carrier_name  : ''}&type_sl=${this.filter?.type_sl ? this.filter?.type_sl : ""}&status=${this.filter?.status ? this.filter?.status : ""}`
        )
        .then((response) => {
          // &expired_date=${this.serverParams.columnFilters.expired_date}
          this.header = response.data.datas.header;
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmFuelMasterController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loading();
    // this.loadItems();
    this.loadCarier();
  },
};
</script>
<style scoped></style>
