import Driver from "@/components/master/driver/Driver.vue";
import DriverAdd from "@/components/master/driver/DriverAdd.vue";
import DriverDetail from "@/components/master/driver/DriverDetail.vue";
import DriverEdit from "@/components/master/driver/DriverEdit.vue";
import DriverUpload from "@/components/master/driver/DriverUpload.vue";

let routesDriver = [
    
    {
        path: '',
        meta: {
          title: "Chain System",
        },
        name: "DriverIndex",
        component: Driver
    },
    {
        path: 'add',
        meta: {
          title: "Chain System",
        },
        name: "DriverAdd",
        component: DriverAdd
    },
    {
        path: 'detail/:id',
        meta: {
          title: "Chain System",
        },
        name: "DriverDetail",
        component: DriverDetail
    },
    {
        path: 'edit/:id',
        meta: {
          title: "Chain System",
        },
        name: "DriverEdit",
        component: DriverEdit
    },
    {
        path: 'upload',
        meta: {
          title: "Chain System",
        },
        name: "DriverUpload",
        component: DriverUpload
    },
   
  ]
  
  export default routesDriver
  
  