var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.isLoading)?_c('div',{staticClass:"loader"}):_vm._e(),(this.isLoading)?_c('div',{staticClass:"loadertext"},[_vm._v(" "+_vm._s(_vm.$t("loadingTxt"))+"... ")]):_vm._e(),_c('div',{staticClass:"main-wrapper",class:{ 'blur-content': this.isLoading }},[_c('sidebar-component',{attrs:{"classMenu":"TransportOrder"}}),_c('div',{staticClass:"page-wrapper"},[_c('navbar-component'),_vm._v("\\ "),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"col-lg-12 col-xl-12 stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"float-start",staticStyle:{"margin-right":"5px"}},[_vm._v(" New Transport Order Multiple ")]),(_vm.permision_role.method_create)?_c('button',{staticClass:"btn btn-primary float-end btn-xs",staticStyle:{"margin-right":"5px"},on:{"click":function($event){$event.preventDefault();return _vm.createData()}}},[_c('i',{staticClass:"link-icon",attrs:{"data-feather":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("createNew"))+" ")]):_vm._e(),(_vm.permision_role.method_read)?_c('button',{staticClass:"btn btn-success float-end btn-xs",staticStyle:{"margin-right":"5px"},on:{"click":function($event){$event.preventDefault();return _vm.downloadData()}}},[_c('i',{staticClass:"link-icon",attrs:{"data-feather":"download"}}),_vm._v(" "+_vm._s(_vm.$t("downloadData"))+" .xlsx ")]):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{ref:"list-transport-orders",attrs:{"title":"list-transport-orders","mode":"remote","select-options":{
                  enabled: true,
                  selectAllByGroup: true,
                  selectOnCheckboxOnly: true,
                  selectionText: _vm.$t('data_selected'),
                  clearSelectionText: _vm.$t('clear'),
                },"totalRows":_vm.totalRecords,"line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
                  enabled: true,
                },"rows":_vm.rows,"columns":_vm.columns,"group-options":{
                  enabled: true,
                  collapsable: true,
                }},on:{"on-selected-rows-change":_vm.selectionChanged,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'download')?_c('span',[(_vm.permision_role.method_read)?_c('a',{attrs:{"href":"#","title":"download"},on:{"click":function($event){$event.preventDefault();return _vm.downloadDataFile(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-download",staticStyle:{"font-size":"16px"}})]):_vm._e()]):_vm._e()]}},{key:"table-header-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[(_vm.permision_role.method_read)?_c('a',{attrs:{"href":"#","title":"Detail"},on:{"click":function($event){$event.preventDefault();return _vm.detailData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-eye",staticStyle:{"font-size":"16px"}})]):_vm._e(),(
                        _vm.permision_role.method_update &&
                        props.row.order_status == 'NEW'
                      )?_c('a',{attrs:{"href":"#","title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.editData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-square-edit-outline",staticStyle:{"font-size":"16px"}})]):_vm._e(),(
                        _vm.permision_role.method_delete &&
                        props.row.order_status == 'NEW'
                      )?_c('a',{attrs:{"href":"#","title":"Delete"},on:{"click":function($event){$event.preventDefault();return _vm.deleteData(props.index, props.row)}}},[_c('i',{staticClass:"mdi mdi-trash-can-outline",staticStyle:{"font-size":"16px"}})]):_vm._e()]):(props.column.field == 'order_status')?_c('span',[_vm._v(" "+_vm._s(_vm.orderStatus(props.row.order_status))+" ")]):(props.column.field == 'request')?_c('span',[(
                        !props.row.load_id &&
                        props.row.order_status ==
                          'RESCHEDULE - WAITING APPROVAL'
                      )?_c('a',{attrs:{"href":"#","title":"Approval Request"},on:{"click":function($event){$event.preventDefault();return _vm.showApproval(props.row)}}},[_c('i',{staticClass:"mdi mdi-alarm-light",staticStyle:{"font-size":"16px"}})]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_vm._v(" "+_vm._s(_vm.$t("no_select_data"))+" ")]),_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',{staticClass:"btn btn-success btn-xs",staticStyle:{"margin-right":"20px"},on:{"click":_vm.sendOrder}},[_c('i',{staticClass:"mdi mdi-send-outline"}),_vm._v(" "+_vm._s(_vm.$t("send_order_txt"))+" ")]),_c('button',{staticClass:"btn btn-warning btn-xs",staticStyle:{"margin-right":"20px"},on:{"click":function($event){return _vm.cancelOrder('void')}}},[_c('i',{staticClass:"mdi mdi-close"}),_vm._v(" "+_vm._s(_vm.$t("cancel_order_txt"))+" ")]),_c('button',{staticClass:"btn btn-danger btn-xs",on:{"click":function($event){return _vm.cancelOrder('cancel_from_costumer')}}},[_c('i',{staticClass:"mdi mdi-close"}),_vm._v(" "+_vm._s(_vm.$t("cancel_order_costumer_txt"))+" ")])])])],1)])])]),_c('modal-cancel-order',{attrs:{"showModal":_vm.showModalCancelOrder,"data":this.transport_order_header_ids,"status":this.status_order},on:{"closeModal":_vm.closeMyModal}}),_c('modal-request-reschedule',{attrs:{"showModal":_vm.showModalRequestReschedule,"data":_vm.passData},on:{"closeModal":_vm.closeMyModal}}),_c('modal-request-reroute',{attrs:{"showModal":_vm.showModalRequestReroute,"data":_vm.passData},on:{"closeModal":_vm.closeMyModal}}),_c('modal-approval-reschedule',{attrs:{"showModal":_vm.showModalReschedule,"data":_vm.passData},on:{"closeModal":_vm.closeMyModal}}),_c('footer-component')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }