<template>
  <div>
    <div class="main-wrapper">
      <sidebar-component
        classMenu="BillableRateManagementAddAcc"
      ></sidebar-component>
      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12 pe-2">
            <div class="card">
              <form
                class="forms-sample"
                @submit.prevent="submitData"
                method="POST"
              >
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="row">
                        <b>Customer</b>
                      </div>
                      <div class="row">
                        <b>{{ this.rateDetail.customer_name }}</b>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div>
                        <label class="form-label"
                          >Billable Tariff<span class="wajib">*</span></label
                        >
                        <v-select
                          :options="fetchBillableTariff"
                          v-model="billableTariff"
                          label="tariff_name"
                        >
                        </v-select>
                        <div
                          v-for="error in errors.billable_tariff_name"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div>
                        <label class="form-label"
                          >Opportunity Id</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          :disabled="true"
                          v-model="forms.opty_id"
                        />
                        <div
                          v-for="error in errors.opty_id"
                          :key="error"
                          class="alert alert-primary"
                          role="alert"
                        >
                          <i data-feather="alert-circle"></i>
                          {{ error }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <div
                    class="d-flex justify-content-between align-items-baseline mb-2"
                  ></div>

                  <div class="row mb-3">
                    <!-- Col -->
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="mb-3">
                          <h5>Origin Detail (Optional)</h5>

                          <label class="form-label">Geography </label>
                          <v-select
                            :options="fetchGeography"
                            v-model="originGeography"
                            label="Geography"
                            @option:selected="changeGeography($event, 'origin')"
                          >
                          </v-select>

                          <div
                            v-for="error in errors.geography"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <h5>&nbsp;</h5>
                      <div v-if="this.originGeography == 'Stop Location'">
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">Location</label>
                            <multiselect
                              @search-change="asyncSearchDestLocation"
                              v-model="originGeographyLocation"
                              :options="fetchLocation"
                              label="name"
                              track-by="name"
                              placeholder="Please Select"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>

                            <div
                              v-for="error in errors.origin_location"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="this.originGeographyLocation !== []"
                        >
                          <div class="col-sm-6">
                            <p class="text-muted">
                              <b>{{ this.originGeographyLocation.name }}</b
                              ><br />
                              <i class="mdi mdi-map-marker"></i>Lat.{{
                                this.originGeographyLocation.latitude
                              }}
                              Long.{{ originGeographyLocation.longitude }}
                              <br />
                              {{ originGeographyLocation.address1 }}
                              {{ originGeographyLocation.address2 }}
                              {{ originGeographyLocation.address2 }}
                              {{ originGeographyLocation.sub_district }},
                              {{ originGeographyLocation.district }},
                              {{ originGeographyLocation.city }},
                              {{ originGeographyLocation.province }}
                              {{ originGeographyLocation.postal_code }},
                              {{ originGeographyLocation.country }}<br />
                              {{ originGeographyLocation.contact_name }} -
                              {{ originGeographyLocation.phone_number }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="this.originGeography == 'Region'">
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">Region</label>
                            <multiselect
                              @search-change="asyncSearchRegion"
                              v-model="originGeographyRegion"
                              :options="fetchRegion"
                              label="region_code"
                              track-by="region_code"
                              placeholder="Please Select"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>

                            <div
                              v-for="error in errors.origin_region"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Col -->
                  </div>
                  <div class="row mb-3">
                    <!-- Col -->
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="mb-3">
                          <h5>Destination Detail (Optional)</h5>

                          <label class="form-label">Geography</label>
                          <v-select
                            :options="fetchGeography"
                            v-model="destGeography"
                            label="Geography"
                            @option:selected="changeGeography($event, 'dest')"
                          >
                          </v-select>

                          <div
                            v-for="error in errors.geography"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <h5>&nbsp;</h5>
                      <div v-if="this.destGeography == 'Stop Location'">
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">Location</label>
                            <multiselect
                              @search-change="asyncSearchDestLocation"
                              v-model="destGeographyLocation"
                              :options="fetchLocation"
                              label="name"
                              track-by="name"
                              placeholder="Please Select"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>

                            <div
                              v-for="error in errors.destination_location"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="this.destGeographyLocation !== []"
                        >
                          <div class="col-sm-6">
                            <p class="text-muted">
                              <b>{{ this.destGeographyLocation.name }}</b
                              ><br />
                              <i class="mdi mdi-map-marker"></i>Lat.{{
                                this.destGeographyLocation.latitude
                              }}
                              Long.{{ destGeographyLocation.longitude }}
                              <br />
                              {{ destGeographyLocation.address1 }}
                              {{ destGeographyLocation.address2 }}
                              {{ destGeographyLocation.address2 }}
                              {{ destGeographyLocation.sub_district }},
                              {{ destGeographyLocation.district }},
                              {{ destGeographyLocation.city }},
                              {{ destGeographyLocation.province }}
                              {{ destGeographyLocation.postal_code }},
                              {{ destGeographyLocation.country }}<br />
                              {{ destGeographyLocation.contact_name }} -
                              {{ destGeographyLocation.phone_number }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="this.destGeography == 'Region'">
                        <div class="row">
                          <div class="mb-3">
                            <label class="form-label">Region</label>
                            <multiselect
                              @search-change="asyncSearchRegion"
                              v-model="destGeographyRegion"
                              :options="fetchRegion"
                              label="region_code"
                              track-by="region_code"
                              placeholder="Please Select"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>

                            <div
                              v-for="error in errors.destination_region"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Col -->
                  </div>
                  <div class="row mb-3">
                    <h4>Accessorial Details</h4>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="mb-3 col-sm-10">
                          <label class="form-label"
                            >Charge Code <span class="wajib">*</span></label
                          >
                          <multiselect
                            @search-change="asyncChargeCode"
                            v-model="chargeCode"
                            :options="fetchChargeCode"
                            label="description_overide"
                            :readonly="false"
                            track-by="description_overide"
                            placeholder="Please Select"
                          >
                            <span slot="noResult">-</span>
                          </multiselect>

                          <div
                            v-for="error in errors.charge_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Tier <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchTier"
                              v-model="tier"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.tier"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Equipment Type
                              <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchEquipmentType"
                              v-model="equipmentType"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.equipment_type"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Division <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchDivision"
                              v-model="division"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.division"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Order Group <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchOrderGroup"
                              v-model="orderGroup"
                              @search="asyncSearchOrderGroup"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.order_group"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Sub Tariff <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchSubTariff"
                              v-model="subTariff"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.sub_tariff"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Basis <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchBasis"
                              v-model="basis"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.basis"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Effective Date
                              <span class="wajib">*</span></label
                            >
                            <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              v-model="effective_date"
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              @update="EffectiveDateChange"
                            />

                            <div
                              v-for="error in errors.effective_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Expired Date <span class="wajib">*</span></label
                            >
                            <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              v-model="expiration_date"
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              :date-format="dateFormatExpired"
                            />
                            <div
                              v-for="error in errors.expired_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Rate <span class="wajib"></span
                            ></label>
                            <input
                              type="number"
                              class="form-control"
                              step="any"
                              v-model.number="forms.rate"
                            />
                            <div
                              v-for="error in errors.rate"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Currency <span class="wajib">*</span></label
                            >
                            <v-select
                              :options="fetchCurrency"
                              v-model="currency"
                              label="code"
                              return-object
                            ></v-select>

                            <div
                              v-for="error in errors.currency"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-10">
                          <div class="mb-3">
                            <label class="form-label"
                              >Cost Quantity <span class="wajib"></span
                            ></label>
                            <input
                              type="number"
                              class="form-control"
                              step="any"
                              v-model.number="forms.cost_quantity"
                            />
                            <div
                              v-for="error in errors.cost_quantity"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Min <span class="wajib"></span
                            ></label>
                            <input
                              type="number"
                              class="form-control"
                              step="any"
                              v-model.number="forms.min"
                            />
                            <div
                              v-for="error in errors.min"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="mb-3">
                            <label class="form-label"
                              >Max <span class="wajib"></span
                            ></label>
                            <input
                              type="number"
                              class="form-control"
                              step="any"
                              v-model.number="forms.max"
                            />
                            <div
                              v-for="error in errors.max"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    class="btn btn-warning text-black fw-bolder btn-icon-text"
                    @click="backForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="arrow-left"></i>
                    {{ $t("backMess") }}
                  </button>
                  <button
                    v-if="!isLoading"
                    class="btn btn-success float-end btn-sm"
                    type="submit"
                  >
                    <i class="link-icon" data-feather="save"></i>
                    {{ $t("submitOrderTxt") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  name: "BillableRateManagementAddAcc",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,

    multiselect: multiselect,
    DateRangePicker,
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      opens: "center",
      effective_date: {
        startDate,
        endDate,
      },
      expiration_date: {
        startDate,
        endDate,
      },
      detailAll: [],
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      forms: {},
      rateDetail: {},

      fetchSubTariff: [],
      subTariff: null,

      fetchBasis: [],
      basis: null,

      fetchOrderGroup: [],
      orderGroup: null,

      fetchDivision: [],
      division: null,

      fetchEquipmentType: [],
      equipmentType: null,

      fetchTier: [],
      tier: null,

      fetchCurrency: [],
      currency: null,

      fetchChargeCode: [],
      chargeCode: null,

      fetchBillableTariff: [],
      billableTariff: null,

      fetchGeography: ["Stop Location", "Region"],
      fetchRegion: [],
      fetchLocation: [],

      originGeography: null,
      originGeographyLocation: [],
      originGeographyRegion: [],

      destGeography: null,
      destGeographyLocation: [],
      destGeographyRegion: [],
    };
  },
  methods: {
    submitData() {
      var effective_date = moment(this.effective_date.startDate).format(
        "YYYY-MM-DD HH:mm"
      );
      var expiration_date = moment(this.expiration_date.startDate).format(
        "YYYY-MM-DD HH:mm"
      );
      this.forms.rm_billable_rate_header_id =
        this.rateDetail.rm_billable_rate_header_id;
      this.forms.rm_billable_tariff_id =
        this.billableTariff?.rm_billable_tariff_id || null;
      this.forms.billable_tariff_name =
        this.billableTariff?.tariff_name || null;
      this.forms.origin_location =
        this.originGeographyLocation?.location_code || null;
      this.forms.origin_region =
        this.originGeographyRegion?.region_code || null;
      this.forms.destination_location =
        this.destGeographyLocation?.location_code || null;
      this.forms.destination_region =
        this.destGeographyRegion?.region_code || null;
      this.forms.charge_code_id = this.chargeCode?.rm_charge_code_id || null;
      this.forms.charge_code = this.chargeCode?.description_overide || null;
      this.forms.sub_tariff = this.subTariff;
      this.forms.basis = this.basis;
      this.forms.tier = this.tier;
      this.forms.equipment_type = this.equipmentType;
      this.forms.division = this.division;
      this.forms.effective_date = effective_date;
      this.forms.expired_date = expiration_date;
      this.forms.order_group = this.orderGroup;
      this.forms.currency = this.currency;
      var params = this.$onBehind(this.$route.params.detailId);
      const baseURI =
        this.$settings.endPoint + "rm-billable-rate-acc/update/" + params[0];
      this.fade(true);
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          if (response.data.status === 200) {
            this.errors = [];
            // console.log(response);
            this.loading();
            window.location.href =
              "/billable-rate/detail/" + this.$route.params.id;
            this.success("Berhasil");
          } else {
            this.loading();
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
        });
      // console.log(this.forms);
    },
    EffectiveDateChange(ev) {
      var effectiveDate = moment(ev.startDate).format("YYYY-MM-DD");
      var ExpDate = moment(this.expiration_date.startDate).format("YYYY-MM-DD");
      if (ExpDate < effectiveDate) {
        this.expiration_date = {
          startDate: moment(effectiveDate).add(1, "days"),
          endDate: moment(effectiveDate).add(1, "days"),
        };
      }
    },
    dateFormatExpired(classes, date) {
      if (!classes.disabled) {
        classes.disabled =
          date.getTime() - 86400000 <= new Date(this.effective_date.startDate);
      }
      return classes;
    },
    changeGeography(ev, field) {
      if (ev !== "Stop Location") {
        if (field == "origin") {
          this.originGeographyLocation = [];
        } else {
          this.destGeographyLocation = [];
        }
      }
      if (ev !== "Region") {
        if (field == "origin") {
          this.originGeographyRegion = [];
        } else {
          this.destGeographyRegion = [];
        }
      }
    },
    loadEquipmentType() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmet`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchEquipmentType = response.data.datas;
        this.fade(false);
      });
    },
    loadTier() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmtier`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchTier = response.data.datas;
        this.fade(false);
      });
    },
    backForm() {
      window.location.href = "/billable-rate/detail/" + this.$route.params.id;
    },
    loadDivision() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmdiv`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchDivision = response.data.datas;
        this.fade(false);
      });
    },
    loadDestinationLocation() {
      const baseURI =
        this.$settings.endPoint +
        `location?company_name=` +
        this.rateDetail.customer_name;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fade(false);
      });
    },
    loadCurrency() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmcur`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchCurrency = response.data.datas;
        this.fade(false);
      });
    },
    loadSubTariff() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmstf`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchSubTariff = response.data.datas;
        this.fade(false);
      });
    },
    loadBasis() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmbas`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchBasis = response.data.datas;
        this.fade(false);
      });
    },
    asyncSearchOrderGroup(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=rmog&search=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchOrderGroup = response.data.datas;
      });
    },
    loadOrderGroup() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=rmog`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchOrderGroup = response.data.datas;
        this.fade(false);
      });
    },
    asyncSearchDestLocation(ev) {
      let company_name = this.rateDetail.customer_name;
      const baseURI =
        this.$settings.endPoint +
        "location?company_name=" +
        company_name +
        "&location_name=" +
        ev;
      this.fetchLocation = [];
      return this.$http.get(baseURI).then((response) => {
        // console.log("search : " + ev);
        // console.log(response.data.datas.data);
        this.fetchLocation = response.data.datas.data;
      });
    },
    loadChargeCode() {
      const baseURI = this.$settings.endPoint + "rm-charge-code";
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchChargeCode = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncChargeCode(ev) {
      const baseURI =
        this.$settings.endPoint + "rm-charge-code?description_overide=" + ev;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchChargeCode = response.data.datas.data;
        this.fade(false);
      });
    },
    loadRegion() {
      const baseURI = this.$settings.endPoint + "region";
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncSearchRegion(ev) {
      const baseURI = this.$settings.endPoint + `region?region_code=${ev}`;
      this.fetchRegion = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
      });
    },

    loadBillableTariff() {
      const baseURI =
        this.$settings.endPoint +
        `rm-billable-tariff?customer_code=${this.rateDetail.customer_code}`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchBillableTariff = response.data.datas.data;
        this.fade(false);
      });
    },
    loadData(id) {
      this.fade(true);
      const baseURI =
        this.$settings.endPoint + "rm-billable-rate-header/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.rateDetail = response.data.datas.header;

        this.loadRegion();
        this.loadDestinationLocation();

        this.loadOrderGroup();
        this.loadTier();
        this.loadDivision();
        this.loadSubTariff();
        this.loadCurrency();
        this.loadBasis();
        this.loadChargeCode();
        this.loadEquipmentType();
        this.loadBillableTariff();
        this.loadDetail();
      });
    },
    loadDetail() {
      this.fade(true);
      var paramsDetail = this.$onBehind(this.$route.params.detailId);
      const baseURI =
        this.$settings.endPoint + "rm-billable-rate-acc/detail/" + paramsDetail;
      return this.$http.get(baseURI).then((response) => {
        let data = response.data.datas;
        this.chargeCode = {
          rm_charge_code_id: data.charge_code_id,
          description_overide: data.charge_code,
        };
        this.billableTariff = {
          rm_billable_tariff_id: data.rm_billable_tariff_id,
          tariff_name: data.billable_tariff_name,
        };
        this.subTariff = data.sub_tariff;
        this.basis = data.basis;
        this.tier = data.tier;
        this.equipmentType = data.equipment_type;
        this.division = data.division;
        this.orderGroup = data.order_group;
        this.currency = data.currency;
        this.effective_date = {
          startDate: new Date(data.effective_date),
          endDate: new Date(data.effective_date),
        };
        this.expiration_date = {
          startDate: new Date(data.expired_date),
          endDate: new Date(data.expired_date),
        };
        this.forms.rate = data.rate;
        this.forms.min = data.min;
        this.forms.max = data.max;
        this.forms.cost_quantity = data.cost_quantity;
        this.forms.opty_id = data.opty_id;

        if (data.origin_region !== "" && data.origin_region) {
          this.originGeography = "Region";
          this.originGeographyRegion = {
            region_code: data.origin_region,
          };
        }
        if (data.destination_region !== "" && data.destination_region) {
          this.destGeography = "Region";
          this.destGeographyRegion = {
            region_code: data.origin_region,
          };
        }
        if (data.origin_location !== "" && data.origin_location) {
          this.originGeography = "Stop Location";
          this.loadLocationDetail(data.origin_location, "origin");
        }
        if (data.destination_location !== "" && data.destination_location) {
          this.destGeography = "Stop Location";
          this.loadLocationDetail(data.destination_location, "dest");
        }
        // console.log(response);
      });
    },
    loadLocationDetail(location_code, field) {
      const baseURI =
        this.$settings.endPoint + `location?location_code=` + location_code;
      return this.$http.get(baseURI).then((response) => {
        // console.log(response.data.datas?.data[0]);
        // field = response.data.datas?.data[0];
        if (field == "origin") {
          this.originGeographyLocation = response.data.datas?.data[0];
        }
        if (field == "dest") {
          this.destGeographyLocation = response.data.datas?.data[0];
        }
        // this.destLocation = response.data.datas;
        // this.destLocation.location_name = this.destLocation.name;
      });
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmBillableRateAccController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.fetchIt();
    this.loadData(params[0]);
  },
};
</script>

<style></style>
