<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="StopLocationAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Manage Service Request Application Rule
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>
                    
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >File Upload Cross Reference
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control"
                              id="reference"
                              v-model="forms.file_upload_cross_reference"
                              required
                            />

                            <div v-if="errors.file_upload_cross_reference">
                              <div
                                v-for="error in errors.file_upload_cross_reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Effective Date</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              v-model="effective_date"
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker="true"
                             
                            >
                            </date-range-picker>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Expiration Date
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <date-range-picker
                              style="width: 100%"
                              :locale-data="locale"
                              v-model="expiration_date"
                              :opens="opens"
                              :singleDatePicker="true"
                              :timePicker="true"
                            
                            >
                            </date-range-picker>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Order Group
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <v-select
                              :options="fetchOrderGroup"
                              v-model="orderGroup"
                              label="code"
                              disabled
                              return-object
                            ></v-select>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Origin Regions
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <v-select
                              :options="fetchOriginRegions"
                              v-model="originRegions"
                              label="code"
                              return-object
                            ></v-select>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Customers
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <v-select
                              :options="fetchCustomer"
                              v-model="customer"
                              label="code"
                              disabled
                              return-object
                            ></v-select>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Service Request Code
                              <span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-lg-7">
                            <v-select
                              :options="fetchServiceRequestCode"
                              v-model="serviceRequestCode"
                              label="description_overide"
                              @search="searchServiceReqCode"
                              return-object
                            ></v-select>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Stop Type</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <v-select
                              :options="fetchStopType"
                              v-model="stopType"
                              label="code"
                              disabled
                              return-object
                            ></v-select>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Stop Type</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control"
                              id="reference"
                              v-model="forms.reference"
                              required
                            />

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Destination Region</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <v-select
                              :options="fetchDestinationRegion"
                              v-model="destinationRegion"
                              label="code"
                              disabled
                              return-object
                            ></v-select>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Suppliers</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <v-select
                              :options="fetchSuppliers"
                              v-model="supplier"
                              label="code"
                              disabled
                              return-object
                            ></v-select>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Apply Level</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <v-select
                              :options="fetchApplyLevel"
                              v-model="applyLevel"
                              label="code"
                              disabled
                              return-object
                            ></v-select>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Stop Locations</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <v-select
                              :options="fetchStopLocation"
                              v-model="stopLocation"
                              label="code"
                              disabled
                              return-object
                            ></v-select>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-5">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Commodities</label
                            >
                          </div>
                          <div class="col-lg-7">
                            <v-select
                              :options="fetchCommodities"
                              v-model="commodities"
                              label="code"
                              disabled
                              return-object
                            ></v-select>

                            <div v-if="errors.reference">
                              <div
                                v-for="error in errors.reference"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import moment from "moment";
export default {
  name: "BillablePartiesAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    DateRangePicker,
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      isLoading: false,
      fetchTimeZone: [
        {
          name: "(GMT + 07.00 Asia / Jakarta)",
        },
      ],
      timeZone: "",

      fetchCountry: [
        {
          name: "Indonesia",
        },
      ],
      locale: {
        format: "dd mmm",
      },
      opens: "center",
      effective_date: {
        startDate,
        endDate,
      },
      expiration_date: {
        startDate,
        endDate,
      },

      fetchOrderGroup: [{
        'code':'--All--'
      }],
      orderGroup: {
        'code':'--All--'
      },
      fetchOriginRegions: [{
        'code':'--All--'
      }],
      originRegions: {
        'code':'--All--'
      },
      fetchCustomer: [{
        'code':'--All--'
      }],
      customer: {
        'code':'--All--'
      },
      fetchServiceRequestCode: [],
      serviceRequestCode: null,
      fetchStopType: [{
        'code':'Either'
      }],
      stopType: {
        'code':'Either'
      },

      fetchDestinationRegion: [{
        'code':'--All--'
      }],
      destinationRegion: {
        'code':'--All--'
      },

      fetchSuppliers: [{
        'code':'--All--'
      }],
      supplier: {
        'code':'--All--'
      },

      fetchApplyLevel: [{
        'code':'Shipment Leg'
      }],
      applyLevel:{
        'code':'Shipment Leg'
      },

      fetchStopLocation: [{
        'code':'--All--'
      }],
      stopLocation: {
        'code':'--All--'
      },

      fetchCommodities: [{
        'code':'--All--'
      }],
      commodities:{
        'code':'--All--'
      },

      fetchCompany: [],
      reference: [],
      country: "",
      fetchCountryCode: [],
      featchProvinceCode: [],
      featchCityCode: [],
      city: "",
      province: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      companyCode: "",
      forms: { enabled: true },
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms = [];
    },

    backForm() {
      window.location.href = "/billable-manage-service";
    },

    submitData() {
      this.fade(true);
      // this.forms.country = this.country.country;
      
      this.forms.origin_regions=this.originRegions.code;
      this.forms.destination_regions=this.destinationRegion.code;
      this.forms.stop_locations=this.stopLocation.code;
      this.forms.stop_type=this.stopType.code;
      this.forms.customers=this.customer.code;
      this.forms.suppliers=this.supplier.code;
      this.forms.commodities=this.commodities.code;
      this.forms.order_group=this.orderGroup.code;


      this.forms.service_request_code=this.serviceRequestCode.description_overide;
      this.forms.apply_level=this.applyLevel.code;


      var starDateEff=this.effective_date.startDate.toString();
      var starDateExpired=this.expiration_date.startDate.toString();
      //console.log(starDateEff);
      var effective_date_=starDateEff.split(' ');
      var expiration_date_=starDateExpired.split(' ');
      
      this.forms.effective_month=effective_date_[1];
      this.forms.effective_date=effective_date_[2];

      this.forms.expiration_month=expiration_date_[1];
      this.forms.expiration_date=expiration_date_[2];
     
      console.log(this.forms);


      const baseURI =
        this.$settings.endPoint + "rm-service-request-app/update/"+this.forms.rm_service_request_app_id;
      this.$http
        .patch(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(
              this.forms.rm_service_request_app_id
            );
            window.location.href =
              "/billable-manage-service/detail/" +
              params +
              "?status=200&msg=Successfully";
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },


    loadServiceReqCode() {
      const baseURI =
        this.$settings.endPoint + `rm-charge-code`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchServiceRequestCode = response.data.datas.data;
        this.fade(false);
      });
    },

    searchServiceReqCode(ev) {
      this.fetchServiceRequestCode=[];
      const baseURI =
        this.$settings.endPoint + `rm-charge-code?description_overide=`+ev;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchServiceRequestCode = response.data.datas.data;
        this.fade(false);
      });
    },


    loadData(id) {
        const baseURI = this.$settings.endPoint + "/rm-service-request-app/detail/" + id;
        return this.$http.get(baseURI).then((response) => {
          
          this.forms = response.data.datas;
          this.serviceRequestCode={
            'description_overide': this.forms.service_request_code
          }
          
          //  this.effective_date= {
          //       startDate: new Date(this.forms.pick_plan),
          //       endDate: new Date(this.forms.pick_plan),
          //   },
            var effectiveData='2023-'+this.forms.effective_month +'-'+this.forms.effective_date ;
            var expirationData='2023-'+this.forms.expiration_date +'-'+this.forms.expiration_month ;
            this.effective_date= {
                startDate: new Date(effectiveData),
                endDate: new Date(effectiveData),
            }

            this.expiration_date={
              startDate:new Date(expirationData),
              endDate: new Date(expirationData),
            }

         
          // this.form={ name: 'asep', usernmae:res.username };
        });
      },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          setTimeout(() => {
            // if(this.company_id==null || this.company_id=='' || this.company_id==undefined){
            //   window.location.href = '/billable-method';
            // }else{
            //   var params = this.$onRandom(this.company_id);
            //   window.location.href = '/billable-method?company_id='+params;
            // }
          }, 3000);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (
          permision.permission.controller ==
          "RmServiceRequestAppController"
        ) {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    loadCompany() {
      const baseURI = this.$settings.endPoint + "company";
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
        })
        .catch((error) => {
          //this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              // if(error.response.status === 422) {
              //   this.errors = error.response.data.errors;
              //   this.resultError(error.response.data.errors);
              // }else if (error.response.status === 500) {
              //     this.$router.push('/server-error');
              // }else if (error.response.status === 401) {
              //     this.$router.push("/authorized-error");
              // }else{
              //     this.$router.push('/page-not-found');
              // }
            }
          }
        });
    },

    asyncSearchCompany(ev) {
      this.fetchCompany = [];
      const baseURI = this.$settings.endPoint + "company?sap_code=" + ev;
      //this.isLoading = true;

      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.fetchCompany = response.data.datas.data;
        })
        .catch((error) => {
          //this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },

    // success(kata) {
    //   const Toast = this.$swal.mixin({
    //     toast: true,
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 3000,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //       toast.addEventListener("mouseenter", this.$swal.stopTimer);
    //       toast.addEventListener("mouseleave", this.$swal.resumeTimer);
    //     },
    //   });
    //   Toast.fire({
    //     icon: "success",
    //     title: kata,
    //   });
    // },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();
    this.loadServiceReqCode()
    //this.loadCountryCode();
    //this.loadCompany();
  },
};
</script>
<style scoped></style>
