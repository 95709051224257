<template>
  <div>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show: active, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Effective / Expiration Date</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              v-on:click="$emit('closeModal')"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Update Effective date
                  </label>
                </div>
                <div class="col-lg-8">
                  <date-range-picker
                    style="width: 100%"
                    :locale-data="locale"
                    v-model="effective_date"
                    :opens="opens"
                    :singleDatePicker="true"
                    :timePicker24Hour="true"
                    :timePicker="true"
                    :showDropdowns="true"
                  >
                  </date-range-picker>
                  <div
                    v-for="error in errors.effective_date"
                    :key="error"
                    class="alert alert-primary"
                    role="alert"
                  >
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label
                    for="defaultconfig"
                    class="col-form-label"
                    style="padding-left: 25px"
                    >Update Expiration date
                  </label>
                </div>
                <div class="col-lg-8">
                  <date-range-picker
                    style="width: 100%"
                    :locale-data="locale"
                    v-model="expiration_date"
                    :opens="opens"
                    :singleDatePicker="true"
                    :timePicker24Hour="true"
                    :timePicker="true"
                    :showDropdowns="true"
                  >
                  </date-range-picker>
                  <div
                    v-for="error in errors.expiration_date"
                    :key="error"
                    class="alert alert-primary"
                    role="alert"
                  >
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-secondary"
              v-on:click="$emit('closeModal')"
            >
              cancel
            </button>
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-success"
              v-on:click="submitData('save')"
            >
              Save & Close
            </button>
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-primary"
              v-on:click="submitData('save_to_sf')"
            >
              Save & Send to SF
            </button>
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-twitter"
              v-on:click="submitData('save_to_blujay')"
            >
              Save & Send to Blujay
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  name: "modalUpdateDate",
  emits: ["closeModal"],
  components: {
    DateRangePicker,
  },
  props: {
    showModal: Boolean,
    data: null,
  },
  watch: {
    showModal: {
      handler(newVal) {
        this.active = newVal;
        const body = document.querySelector("body");
        if (this.active) {
          // For Empty Vue2 Daterange picker use this example
          this.effective_date = {
            startDate: null,
            endDate: null,
          };
          this.expiration_date = {
            startDate: null,
            endDate: null,
          };
        }
        console.log(this.effective_date, this.expiration_date);
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      isLoading: false,
      opens: "center",
      active: this.showModal,
      effective_date: {
        startDate,
        endDate,
      },
      expiration_date: {
        startDate,
        endDate,
      },
      forms: {},
      errors: [],
      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
    };
  },
  methods: {
    submitData(type) {
      this.fade(true);
      var sendData = {
        rm_billable_rate_detail_ids: this.data,
        effective_date: this.effective_date.startDate,
        expiration_date: this.expiration_date.startDate,
        submit: type,
      };
      const baseURI =
        this.$settings.endPoint +
        "rm-billable-rate-header/update-many-expiration";
      return this.$http
        .post(baseURI, sendData)
        .then((response) => {
          if (response.data.status === 200) {
            this.$emit("closeModal");
            this.success2(response.data.datas.messages);
            this.errors = [];
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
          this.fade(false);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
            this.fade(false);
          }
          //this.resetForm();
        });
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },
    success2(msg) {
      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
};
</script>
<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>
