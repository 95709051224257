import { render, staticRenderFns } from "./TransportOrderMulti.vue?vue&type=template&id=61c9a8ae&scoped=true&"
import script from "./TransportOrderMulti.vue?vue&type=script&lang=js&"
export * from "./TransportOrderMulti.vue?vue&type=script&lang=js&"
import style0 from "./TransportOrderMulti.vue?vue&type=style&index=0&id=61c9a8ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c9a8ae",
  null
  
)

export default component.exports