import { render, staticRenderFns } from "./TolRouteUpload.vue?vue&type=template&id=4bfa4e04&scoped=true&"
import script from "./TolRouteUpload.vue?vue&type=script&lang=js&"
export * from "./TolRouteUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bfa4e04",
  null
  
)

export default component.exports