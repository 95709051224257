<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TemplateOrderAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          ></div>

          <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
            <div class="card">
              <div class="card-header">
                <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                &nbsp;&nbsp;&nbsp;
                <h6
                  class="card-title mb-0 float-start"
                  style="padding-left: 25px"
                >
                  Detail - Billable SL (Customer)
                </h6>
              </div>
              <div class="card-body">
                <!--begin::loading-data-->
                <div v-if="isLoading" class="d-flex align-items-center">
                  <div
                    class="spinner-border ms-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                  <strong>{{ $t("loadingTxt") }}...</strong>
                </div>
                <!--end::loading-data-->

                <div
                  class="d-flex justify-content-between align-items-baseline mb-2"
                ></div>
                <div class="row">
                  <div class="col-4">
                    <table>
                      <tr>
                        <td>Kode Customer</td>
                        <td>:</td>
                        <td>{{ this.forms.customer_code }}</td>
                      </tr>
                      <tr>
                        <td>Nama Customer</td>
                        <td>:</td>
                        <td>{{ this.forms.customer_name }}</td>
                      </tr>
                      <td>SAP Code</td>
                      <td>:</td>
                      <td>{{ this.forms.sap_code }}</td>
                    </table>
                  </div>
                </div>

                <br />
                <div class="row">
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label">Billable Tarif</label>
                      <!-- <input type="text" class="form-control" /> -->
                      <v-select
                        :options="fetchBillableTariff"
                        v-model="billableTariff"
                        label="tariff_name"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label">Origin</label>
                      <input
                        type="text"
                        v-model="search.origin"
                        v-on:keyup.enter="searchData"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label">Destination</label>
                      <input
                        type="text"
                        v-model="search.destination"
                        v-on:keyup.enter="searchData"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label">Sku</label>
                      <!-- <input
                        type="text"
                        v-model="search.sku"
                        v-on:keyup.enter="searchData"
                        class="form-control"
                      /> -->
                      <multiselect
                        @search-change="asyncSearchSKU"
                        v-model="sku"
                        name="sku"
                        :options="fetchSku"
                        placeholder="Please Select"
                        track-by="type_truck"
                        label="type_truck"
                      >
                        <span slot="noResult">-</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <div class="mb-3">
                      <label class="form-label">Status</label>
                      <div class="mb-4">
                        <div class="form-check mb-2">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="future"
                            value="future"
                            name="future"
                            v-model="search.status"
                          />
                          <label class="form-check-label" for="future">
                            Future
                          </label>
                        </div>
                        <div class="form-check mb-2">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="current"
                            value="current"
                            name="current"
                            v-model="search.status"
                          />
                          <label class="form-check-label" for="current">
                            Current
                          </label>
                        </div>
                        <div class="form-check mb-2">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="expired"
                            value="expired"
                            name="expired"
                            v-model="search.status"
                          />
                          <label class="form-check-label" for="expired">
                            Expired
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <button
                        class="btn btn-primary"
                        type="button"
                        @click="searchData"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row card">
                  <div class="card-header">
                    <button
                      class="btn btn-success text-white fw-bolder btn-icon-text float-end me-2"
                      @click="addData()"
                      type="button"
                    >
                      <i class="link-icon" data-feather="plus"></i>
                      {{ $t("CreateTxt") }}
                    </button>

                    <!-- <button
                      class="btn btn-warning text-white fw-bolder btn-icon-text float-end me-2"
                      @click="downloadData()"
                      type="button"
                    >
                      <i class="link-icon" data-feather="download"></i>
                      {{ $t("downloadData") }} .xlsx
                    </button> -->
                  </div>
                  <!-- 
                      @on-page-change="onPageChange"
                      @on-sort-change="onSortChange"
                      @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange"
                      :totalRows="totalRecords"
                        @on-selected-rows-change="selectionChanged"

                    -->
                  <div class="card-body">
                    <vue-good-table
                      title="list-user"
                      mode="remote"
                      @on-selected-rows-change="selectionChanged"
                      @on-per-page-change="onPerPageChange"
                      @on-sort-change="onSortChange"
                      @on-page-change="onPageChange"
                      :totalRows="totalRecords"
                      :line-numbers="true"
                      :select-options="{
                        enabled: true,
                        selectOnCheckboxOnly: true,
                        selectionText: $t('data_selected'),
                        clearSelectionText: $t('clear'),
                      }"
                      :isLoading.sync="isLoading"
                      :pagination-options="{
                        enabled: true,
                      }"
                      :rows="rows"
                      :columns="columns"
                    >
                      <div slot="table-actions">
                        {{ $t("no_select_data") }}
                      </div>
                      <div slot="selected-row-actions">
                        <button
                          type="button"
                          class="btn btn-warning btn-xs me-2"
                          style="margin-right: 20px"
                          @click="updateDate"
                        >
                          <i class="mdi mdi-calendar"></i>
                          {{ $t("dateUpdate") }}
                        </button>

                        <button
                          type="button"
                          class="btn btn-primary btn-xs me-2"
                          style="margin-right: 20px"
                          @click="sendOrder('sf')"
                        >
                          <i class="mdi mdi-send-outline"></i>
                          {{ $t("sendToSF") }}
                        </button>

                        <button
                          type="button"
                          class="btn btn-twitter btn-xs me-2"
                          style="margin-right: 20px"
                          @click="sendOrder('blujay')"
                        >
                          <i class="mdi mdi-send-outline"></i>
                          {{ $t("sendToBlujay") }}
                        </button>
                      </div>
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'last_modified'">
                          {{ props.row.updated_at }} - {{ props.row.update_by }}
                        </span>
                        <span v-if="props.column.field == 'actions'">
                          <!-- <a
                            href="#"
                            title="Detail"
                            @click.prevent="detailData(props.index, props.row)"
                          >
                            <i
                              class="mdi mdi-eye"
                              style="font-size: 16px"
                            ></i>
                          </a> -->
                          <a
                            href="#"
                            title="Edit"
                            @click.prevent="editData(props.index, props.row)"
                          >
                            <i
                              class="mdi mdi-square-edit-outline"
                              style="font-size: 16px"
                            ></i>
                          </a>
                          <a
                            href="#"
                            title="Copy"
                            @click.prevent="copyData(props.index, props.row)"
                          >
                            <i
                              class="mdi mdi-content-copy"
                              style="font-size: 16px"
                            ></i>
                          </a>
                          <a
                            href="#"
                            title="Rate Break"
                            @click.prevent="rateBreak(props.index, props.row)"
                          >
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14px"
                              height="14px"
                              viewBox="0 0 300.000000 300.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <!-- <path fill="none" d="M0 0h24v24H0z" />
                                  <path fill="red"
                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" /> -->
                              <g
                                transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
                                fill="#ab0635"
                                stroke="none"
                              >
                                <path
                                  d="M350 2160 c0 -462 4 -840 8 -840 4 0 98 36 207 81 110 44 207 80 215 81 8 0 178 -76 378 -170 l362 -171 222 84 c123 47 228 85 235 85 7 0 62 -34 122 -75 l110 -76 220 103 221 102 0 476 0 475 -342 342 -343 343 -807 0 -808 0 0 -840z m1542 418 l3 -333 328 -3 327 -2 0 -408 0 -408 -166 -77 -166 -77 -109 75 c-59 41 -112 75 -118 75 -5 0 -110 -38 -233 -85 -123 -47 -227 -85 -233 -85 -5 0 -174 77 -374 171 l-363 172 -157 -63 c-86 -34 -162 -64 -168 -67 -10 -4 -13 144 -13 721 l0 726 720 0 720 0 2 -332z m348 7 l245 -245 -248 0 -247 0 0 245 c0 135 1 245 3 245 1 0 112 -110 247 -245z"
                                />
                                <path
                                  d="M646 2654 c-22 -22 -20 -50 6 -68 18 -13 55 -16 198 -16 190 0 220 7 220 54 0 43 -13 46 -216 46 -162 0 -195 -3 -208 -16z"
                                />
                                <path
                                  d="M647 2382 c-22 -24 -21 -45 1 -65 17 -15 68 -17 525 -17 446 0 507 2 521 16 9 8 16 24 16 34 0 10 -7 26 -16 34 -14 14 -75 16 -523 16 -479 0 -509 -1 -524 -18z"
                                />
                                <path
                                  d="M646 2024 c-19 -18 -21 -45 -4 -62 17 -17 1639 -17 1656 0 15 15 15 51 0 66 -9 9 -206 12 -824 12 -725 0 -814 -2 -828 -16z"
                                />
                                <path
                                  d="M646 1794 c-19 -18 -21 -45 -4 -62 17 -17 1639 -17 1656 0 15 15 15 51 0 66 -9 9 -206 12 -824 12 -725 0 -814 -2 -828 -16z"
                                />
                                <path
                                  d="M558 1277 l-208 -91 0 -593 0 -593 1150 0 1150 0 0 630 c0 409 -3 630 -10 630 -5 0 -100 -42 -210 -94 l-200 -94 -127 74 c-71 40 -135 74 -143 74 -8 0 -113 -38 -233 -84 l-217 -84 -361 159 c-198 87 -365 159 -372 158 -7 0 -105 -41 -219 -92z m590 -171 c194 -86 356 -156 360 -156 4 0 106 38 227 85 l219 84 135 -77 c74 -42 138 -76 143 -74 4 1 76 34 158 72 83 39 152 70 155 70 3 0 5 -229 5 -510 l0 -510 -1050 0 -1050 0 0 518 0 517 163 72 c89 39 167 70 172 68 6 -2 169 -73 363 -159z"
                                />
                                <path
                                  d="M642 798 c-17 -17 -15 -44 4 -62 14 -14 103 -16 828 -16 618 0 815 3 824 12 15 15 15 51 0 66 -17 17 -1639 17 -1656 0z"
                                />
                                <path
                                  d="M646 474 c-22 -22 -20 -50 6 -68 20 -14 114 -16 818 -16 704 0 798 2 818 16 27 19 29 60 3 74 -13 7 -289 10 -825 10 -718 0 -806 -2 -820 -16z"
                                />
                              </g>
                            </svg>
                          </a>
                        </span>
                        <span v-else-if="props.column.field == 'rate'">
                          {{ props.row.rate.toLocaleString() }}
                        </span>
                        <span v-else-if="props.column.field == 'all_inclusive'">
                          {{ props.row.all_inclusive == 1 ? 'Yes' : 'No' }}
                        </span>
                        <span v-else-if="props.column.field == 'no_intermediate_stops'">
                          {{ props.row.no_intermediate_stops == 1 ? 'Yes' : 'No' }}
                        </span>
                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </div>

                <div class="card-footer">
                  <button
                    class="btn btn-warning text-black fw-bolder btn-icon-text"
                    @click="backForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="arrow-left"></i>
                    {{ $t("backMess") }}
                  </button>

                  <!-- &nbsp;&nbsp;&nbsp; -->
                  <!-- <button
                    class="btn btn-success float-end btn-sm"
                    type="submit"
                  >
                    <i class="link-icon" data-feather="save"></i>
                    {{ $t("submitFormTxt") }}
                  </button> -->
                </div>
              </div>
            </div>
            <!--end::card-->
            <modal-update-date
              :showModal="showModalUpdateDate"
              :data="passData"
              @closeModal="closeMyModal"
            ></modal-update-date>
            <modal-rate-break
              :showModal="showModalRateBreak"
              :data="passDataRate"
              @closeModal="closeMyModal"
            ></modal-rate-break>
          </div>
        </div>
        <!--end::content-->
        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalUpdateDate from "@/components/modal/modalUpdateDate.vue";
import modalRateBreak from "@/components/modal/modalRateBreak.vue";
import multiselect from "vue-multiselect";
export default {
  name: "CommodityAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-update-date": modalUpdateDate,
    "modal-rate-break": modalRateBreak,
    multiselect: multiselect,
  },
  data() {
    return {
      passData: [],
      showModalUpdateDate: false,
      showModalRateBreak: false,
      fetchBillableTariff: [],
      billableTariff: [],
      detailAll: [],
      billableRateIds: [],
      sku: "",
      fetchSku: [],
      search: {
        sku: "",
        origin: "",
        destination: "",
        status: [],
      },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      passDataRate: [],
      isLoading: false,
      totalRecords: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      companyCode: "",
      company: [],
      forms: {},
      rows: [],
      columns: [
        {
          label: "Action",
          field: "actions",
          width: "80px",
        },
        {
          label: "SoB",
          field: "sob_status",
        },
        {
          label: "Opprtunity Id",
          field: "opportunity_id",
        },
        {
          label: "Unit Type",
          field: "unit_type",
        },
        {
          label: "Tier",
          field: "tier",
          width: "70px",
        },
        {
          label: "Billable Tariff Name",
          field: "billable_tariff_name",
          width: "200px",
        },
        {
          label: this.$t("division"),
          field: "division",
        },
        {
          label: "Order Group",
          field: "order_group",
          width: "200px",
        },
        {
          label: "Origin Location",
          field: "origin_location",
          width: "200px",
        },
        {
          label: "Origin City",
          field: "origin_city",
          width: "100px",
        },
        {
          label: "Origin State/Province",
          field: "origin_state",
          width: "150px",
        },
        {
          label: "Origin Start Postal Code",
          field: "origin_start_postal_code",
          width: "200px",
        },
        {
          label: "Origin End Postal Code",
          field: "origin_end_postal_code",
          width: "200px",
        },
        {
          label: "Origin Country",
          field: "origin_country",
          width: "150px",
        },
        {
          label: "Origin Region",
          field: "origin_region",
          width: "100px",
        },
        // Destination
        {
          label: "Destination Location",
          field: "destination_location",
          width: "200px",
        },
        {
          label: "Destination City",
          field: "destination_city",
          width: "150px",
        },
        {
          label: "Destination State/Province",
          field: "destination_state",
          width: "200px",
        },
        {
          label: "Destination Start Postal Code",
          field: "destination_start_postal_code",
          width: "200px",
        },
        {
          label: "Destination End Postal Code",
          field: "destination_end_postal_code",
          width: "200px",
        },
        {
          label: "Destination Country",
          field: "destination_country",
          width: "150px",
        },
        {
          label: "Destination Region",
          field: "destination_region",
          width: "150px",
        },
        {
          label: "Currency",
          field: "currency",
        },
        {
          label: "Sub Tarif",
          field: "sub_tariff",
        },

        {
          label: "Min",
          field: "min",
        },
        {
          label: "Max",
          field: "max",
        },
        {
          label: "Effective Date",
          field: "effective_date",
          width: "150px",
        },
        {
          label: "Expiration Date",
          field: "expiration_date",
          width: "150px",
        },
        {
          label: "Equipment Type",
          field: "equipment_type",
          width: "150px",
        },
        {
          label: "Accessorial Application Rule",
          field: "accessorial_application_rule",
          width: "200px",
        },
        {
          label: "Sku",
          field: "sku",
        },
        {
          label: "Rate Break",
          field: "rate_break",
          width: "100px",
        },
        {
          label: "Basis",
          field: "basis",
        },
        {
          label: "Rate",
          field: "rate",
        },
        {
          label: "Tariff Type",
          field: "tariff_type",
          width: "100px",
        },
        {
          label: "All Inclusive",
          field: "all_inclusive",
          width: "100px",
        },
        {
          label: "Qty",
          field: "qty",
          width: "100px",
        },
        {
          label: "No Intermediate Stops",
          field: "no_intermediate_stops",
          width: "200px",
        },
        {
          label: "Allocation Method",
          field: "allocation_method",
          width: "250px",
        },
        {
          label: "Last Modified",
          field: "last_modified",
          width: "200px",
        },
      ],
    };
  },
  watch: {},
  methods: {
    updateDate() {
      this.showModalUpdateDate = true;
    },
    rateBreak(index, row) {
      this.passDataRate = {
        id: row.rm_billable_rate_detail_id,
      };
      this.showModalRateBreak = true;
    },
    loadBillableTariff() {
      const baseURI =
        this.$settings.endPoint +
        `rm-billable-tariff?customer_code=${this.forms.customer_code}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchBillableTariff = response.data.datas.data;
      });
    },
    closeMyModal() {
      this.showModalUpdateDate = false;
      this.showModalRateBreak = false;
      this.loadItems();
    },
    asyncSearchSKU(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchSku = response.data.datas.data;
      });
    },
    loadSku() {
      const baseURI = this.$settings.endPointCt + `type-truck`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchSku = response.data.datas.data;
        this.fade(false);
      });
    },
    sendOrder(type) {
      var baseURI = "";
      let data = {};
      if (type == "blujay") {
        baseURI =
          this.$settings.endPoint + "rm-billable-rate-header/send-to-blujay";
      } else if (type == "sf") {
        baseURI =
          this.$settings.endPoint + "rm-billable-rate-header/send-to-sf";
      }

      data = {
        rm_billable_rate_detail_ids: this.billableRateIds,
      };

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {
                if (error.response) {
                  console.log(error.response.data.errors.message);
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.errors = error.response.data.errors;
                    this.error(error.response.data.errors.message);
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push("/page-not-found");
                  }
                }
              }
            });
        }
      });

      // return this.$http
      //   .post(baseURI, data, { timeout: 30000 })
      //   .then((response) => {
      //     console.log(response);
      //     this.loading();
      //   });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
    addData() {
      window.location.href = "/billable-rate/add/" + this.$route.params.id;
    },
    editData(index, row) {
      var params = this.$onRandom(row.rm_billable_rate_detail_id);
      window.location.href =
        "/billable-rate/detail/" + this.$route.params.id + "/edit/" + params;
    },
    detailData(index, row) {
      var params = this.$onRandom(row.rm_billable_rate_detail_id);
      window.location.href =
        "/billable-rate/detail/" + this.$route.params.id + "/detail/" + params;
    },
    success2(msg) {
      let pesan = "";
      msg.forEach((item) => {
        pesan = pesan + "<p>* " + item + "</p>";
      });

      this.$swal({
        icon: "info",
        backdrop: true,
        title: "Information",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    downloadData() {
      var params = this.$onBehind(this.$route.params.id);
      this.fade(true);
      var billableTariff = "";
      if (this.billableTariff?.tariff_name) {
        billableTariff = this.billableTariff.tariff_name;
      }
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";
      this.fade(true);
      const baseURI =
        this.$settings.endPoint + "rm-billable-rate-header/detail/" + params;
      return this.$http
        .get(
          baseURI +
            `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&billable_tariff_name=${billableTariff}&sku=${this.search?.sku}&origin_location=${this.search?.origin}&destination_location=${this.search?.destination}&status=${this.search?.status}&download=download`
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading();
        });
    },
    loadItems() {
      var params = this.$onBehind(this.$route.params.id);
      this.fade(true);
      var billableTariff = "";
      if (this.billableTariff?.tariff_name) {
        billableTariff = this.billableTariff.tariff_name;
      }
      var sku = "";
      if (this.sku?.type_truck) {
        sku = this.sku.type_truck;
      }
      const baseURI =
        this.$settings.endPoint +
        "rm-billable-rate-header/detail/" +
        params +
        `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&billable_tariff_name=${billableTariff}&sku=${sku}&origin_location=${this.search?.origin}&destination_location=${this.search?.destination}&status=${this.search?.status}`;

      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas.header;
        this.rows = response.data.datas.detail.data;
        this.totalRecords = response.data.datas.detail.total;
        this.loadBillableTariff();
        this.loading();
      });
    },
    selectionChanged(ev) {
      this.rowsSelection = [];
      this.billableRateIds = [];
      this.passData = [];
      this.rowsSelection = ev.selectedRows;
      this.rowsSelection.forEach((item) => {
        this.billableRateIds.push(item.rm_billable_rate_detail_id);
        this.passData.push(item.rm_billable_rate_detail_id);
      });
    },
    copyData(index, row) {
      this.fade(true);
      var params = this.$onRandom(row.rm_billable_rate_detail_id);
      window.location.href =
        "/billable-rate/add/" + this.$route.params.id + "?detailId=" + params;
    },
    searchData() {
      this.loadItems();
    },
    backForm() {
      window.location.href = "/billable-rate";
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RateManagementController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.fade(false);
    this.loadItems();
    this.loadSku();
    // this.loadCompany();
    // this.loadCompanyNotLinc();
  },
};
</script>
<style scoped>
</style>