<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="DriverAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 15px">
                    {{ $t("driverAccessAdd") }}
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <form class="forms-sample" @submit.prevent="submitData" method="POST">
                  <div class="card-body">
                    <div v-if="isLoading" class="d-flex align-items-center">
                      <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                      <strong>{{ $t("loadingTxt") }}...</strong>
                    </div>

                    <div class="tab-pane fade show active" id="basic" role="tabpanel" aria-labelledby="basic-info-tab">
                      <ul class="nav nav-tabs" id="myTab2" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="basic-info-tab" data-bs-toggle="tab" href="#basicinfo"
                            role="tab" aria-controls="basicinfo-tab" aria-selected="true">Basic</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="address-tab" data-bs-toggle="tab" href="#address" role="tab"
                            aria-controls="address" aria-selected="false">{{ $t("address") }}</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="sibiling-info-tab" data-bs-toggle="tab" href="#sibiling-info"
                            role="tab" aria-controls="sibiling-info" aria-selected="false">Sibling Info</a>
                        </li>

                        <li class="nav-item">
                          <a class="nav-link" id="recruitment-info-tab" data-bs-toggle="tab" href="#recruitment-info"
                            role="tab" aria-controls="recruitment-info" aria-selected="false">{{ $t("recruitment_info")
                            }}</a>
                        </li>
                        <!-- 
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="driver-id-info-tab"
                              data-bs-toggle="tab"
                              href="#driver-id-info"
                              role="tab"
                              aria-controls="driver-id-info"
                              aria-selected="false"
                              >{{ $t("driver_id") }}</a
                            >
                          </li> -->
                      </ul>
                      <div class="tab-content border border-top-0 p-3" id="myTab2Content">
                        <div class="tab-pane fade show active" id="basicinfo" role="tabpanel"
                          aria-labelledby="basicinfo">


                          <div class="row">
                            <div class="col-lg-6">

                              <div class="row">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">
                                    {{ $t("driver_id") }}</label>
                                </div>
                                <div class="col-lg-8">
                                  <input type="text" class="form-control" id="driver_id" :placeholder="$t('driver_id')"
                                    v-model="forms.driver_id" readonly />
                                  <div v-if="errors.driver_id">
                                    <div v-for="error in errors.driver_id" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label"
                                    style="padding-left: 15px">Status</label>
                                </div>
                                <div class="col-lg-4">
                                  <v-select :options="fetchStatus" v-model="driver_status" label="status" return-object
                                    @input="changeStatus"></v-select>

                                  <div v-if="errors.driver_status">
                                    <div v-for="error in errors.driver_status" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label"
                                    style="padding-left: 15px">Sub</label>
                                </div>
                                <div class="col-lg-4">
                                  <v-select :options="fetchSub" v-model="driver_substatus" label="name"
                                    return-object></v-select>

                                  <div v-if="errors.driver_substatus">
                                    <div v-for="error in errors.driver_substatus" :key="error"
                                      class="alert alert-primary" role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 12px">{{
                      $t("branch_id") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <v-select :options="fetchBranch" v-model="branch" label="branch_name"
                                    return-object></v-select>

                                  <div v-if="errors.branch_id">
                                    <div v-for="error in errors.branch_id" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Status
                                    Remarks</label>
                                </div>
                                <div class="col-lg-10">
                                  <input type="text" class="form-control" id="status_remarks" name="status_remarks"
                                    placeholder="" v-model="forms.status_remark" />

                                  <div v-if="errors.status_remark">
                                    <div v-for="error in errors.status_remark" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("name_of_id_card") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <input type="text" class="form-control" id="name_idcard" name="name_idcard"
                                    placeholder="" v-model="forms.name_idcard" required />

                                  <div v-if="errors.name_idcard">
                                    <div v-for="error in errors.name_idcard" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("nickname") }}</label>
                                </div>
                                <div class="col-lg-4">
                                  <input type="text" class="form-control" id="nickname" name="nickname" placeholder=""
                                    v-model="forms.nickname" required />

                                  <div v-if="errors.nickname">
                                    <div v-for="error in errors.nickname" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("gender") }}</label>
                                </div>

                                <div class="col-lg-4">
                                  <v-select :options="fetchGender" v-model="gender" label="name"
                                    :placeholder="'--Select Gender--'" return-object></v-select>

                                  <div v-if="errors.gender">
                                    <div v-for="error in errors.gender" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                              </div>

                              <div class="row" style="margin-top: 20px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("position") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <v-select :options="fetchPosition" v-model="positions" label="name"
                                    return-object></v-select>

                                  <div v-if="errors.position">
                                    <div v-for="error in errors.position" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 20px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("site_pool") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <v-select :options="fetchPool" v-model="pool" label="pool_name"
                                    return-object></v-select>

                                  <div v-if="errors.site_name">
                                    <div v-for="error in errors.site_name" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 20px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("project") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <v-select :options="fetchProject" v-model="project" label="name"
                                    return-object></v-select>

                                  <div v-if="errors.project">
                                    <div v-for="error in errors.project" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("marital_status") }}</label>
                                </div>
                                <div class="col-lg-4">
                                  <v-select :options="fetchMariedStatus" v-model="maried_status" label="name"
                                    return-object></v-select>

                                  <div v-if="errors.marital_status">
                                    <div v-for="error in errors.marital_status" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("number_of_childer") }}</label>
                                </div>

                                <div class="col-lg-4">
                                  <input type="number" class="form-control" id="children_number" name="children_number"
                                    placeholder="" v-model="forms.children_number" />

                                  <div v-if="errors.children_number">
                                    <div v-for="error in errors.children_number" :key="error"
                                      class="alert alert-primary" role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("id_card_number") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <input type="text" class="form-control" id="id_card_number" name="id_card_number"
                                    placeholder="" v-model="forms.id_card_number" required />

                                  <div v-if="errors.id_card_number">
                                    <div v-for="error in errors.id_card_number" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("education") }}</label>
                                </div>
                                <div class="col-lg-4">
                                  <v-select :options="fetchEducation" v-model="education" label="name"
                                    return-object></v-select>

                                  <div v-if="errors.education">
                                    <div v-for="error in errors.education" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("religion") }}</label>
                                </div>

                                <div class="col-lg-4">
                                  <v-select :options="fetchReligion" v-model="religion" label="name"
                                    return-object></v-select>

                                  <div v-if="errors.religon">
                                    <div v-for="error in errors.religion" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("place_dob") }}</label>
                                </div>
                                <div class="col-lg-4">
                                  <input type="text" class="form-control" id="place_dob" name="place_dob" placeholder=""
                                    v-model="forms.birth_place" required />

                                  <div v-if="errors.education">
                                    <div v-for="error in errors.education" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <Datepicker v-model="forms.birth_date" :bootstrap-styling="true" format="dd-MM-yyyy"
                                    placeholder="Please Select Date..." @input="changeAgeDate()"></Datepicker>

                                  <div v-if="errors.birth_date">
                                    <div v-for="error in errors.birth_date" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("age") }}</label>
                                </div>
                                <div class="col-lg-4">
                                  <input type="number" class="form-control" id="age" name="age" placeholder=""
                                    v-model="forms.age" readonly />

                                  <div v-if="errors.age">
                                    <div v-for="error in errors.age" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("ethnic") }}</label>
                                </div>

                                <div class="col-lg-4">
                                  <v-select :options="fetchEthnic" v-model="ethnic" label="name"
                                    return-object></v-select>

                                  <div v-if="errors.ethnic">
                                    <div v-for="error in errors.ethnic" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">No
                                    Rekening</label>
                                </div>
                                <div class="col-lg-4">
                                  <input type="text" class="form-control" id="no_rekening" name="no_rekening"
                                    placeholder="" v-model="forms.no_rekening" />

                                  <div v-if="errors.no_rekening">
                                    <div v-for="error in errors.no_rekening" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">SIM
                                    No</label>
                                </div>

                                <div class="col-lg-4">
                                  <input type="text" class="form-control" id="sim_no" name="sim_no" placeholder=""
                                    v-model="forms.sim_no" />

                                  <div v-if="errors.sim_no">
                                    <div v-for="error in errors.sim_no" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Aktual
                                    Unit</label>
                                </div>
                                <div class="col-lg-4">
                                  <v-select v-model="forms.type_truck" :options="fetchTypeTruck" label="type_truck"
                                    :reduce="truck => truck.type_truck">



                                  </v-select>


                                  <div v-if="errors.type_truck">
                                    <div v-for="error in errors.type_truck" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label"
                                    style="padding-left: 15px">PIC</label>
                                </div>

                                <div class="col-lg-4">
                                  <input type="text" class="form-control" id="no_rekening" name="no_rekening"
                                    placeholder="" v-model="forms.pic" />

                                  <div v-if="errors.pic">
                                    <div v-for="error in errors.pic" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div class="row">
                                <div class="col-lg-4">
                                  <!-- <label
                                      for="defaultconfig"
                                      class="col-form-label"
                                      style="padding-left: 25px"
                                      >Photo</label
                                    > -->
                                </div>
                                <div class="col-lg-4">
                                  <div v-if="photo_temporary" class="dropify-wrapper has-preview">
                                    <div class="dropify-message">
                                      <span class="file-icon" v-if="!photo_temporary">
                                        <p>
                                          Drag and drop a file here or click
                                        </p>
                                      </span>
                                      <p class="dropify-error">
                                        Ooops, something wrong appended.
                                      </p>
                                    </div>
                                    <div class="dropify-loader" style="display: none"></div>
                                    <div class="dropify-errors-container">
                                      <ul></ul>
                                    </div>
                                    <input type="file" ref="images" accept="image/*" class="form-control"
                                      @change="handleFileUpload($event)" />
                                    <button type="button" class="dropify-clear" @click="removeImages">
                                      Remove
                                    </button>
                                    <div class="dropify-preview" style="display: block">
                                      <img :src="photo_temporary" width="90%" height="80%" />
                                      <span class="dropify-render"></span>
                                      <div class="dropify-infos">
                                        <div class="dropify-infos-inner">
                                          <p class="dropify-filename"></p>
                                          <p class="dropify-infos-message">
                                            Drag and drop or click to replace
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else class="dropify-wrapper">
                                    <div class="dropify-message">
                                      <span class="file-icon">
                                        <p>
                                          Drag and drop a file here or click
                                        </p>
                                      </span>
                                    </div>
                                    <div class="dropify-loader" style="display: none"></div>
                                    <div class="dropify-errors-container">
                                      <ul></ul>
                                    </div>
                                    <input type="file" ref="images" accept="image/*" class="form-control"
                                      @change="handleFileUpload($event)" />
                                    <button type="button" class="dropify-clear">
                                      Remove
                                    </button>
                                    <div class="dropify-preview" style="display: none">
                                      <img :src="photo_temporary" width="90%" height="80%" />
                                      <span class="dropify-render"></span>
                                      <div class="dropify-infos">
                                        <div class="dropify-infos-inner">
                                          <p class="dropify-filename"></p>
                                          <p class="dropify-infos-message">
                                            Drag and drop or click to replace
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div v-if="errors.photo">
                                    <div v-for="error in errors.photo" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 50px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("joined_date") }}</label>
                                </div>
                                <div class="col-lg-4">
                                  <Datepicker v-model="forms.join_date" :bootstrap-styling="true" format="dd-MM-yyyy"
                                    placeholder="Please Select Date..." @input="changeJoinDate()"></Datepicker>

                                  <div v-if="errors.join_date">
                                    <div v-for="error in errors.join_date" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("working_time") }}</label>
                                </div>

                                <div class="col-lg-2">
                                  <input type="number" class="form-control" id="year" name="year"
                                    :placeholder="$t('year')" readonly v-model="forms.working_year" required />
                                </div>

                                <div class="col-lg-2">
                                  <input type="number" class="form-control" id="month" name="month" readonly
                                    :placeholder="$t('month')" v-model="forms.working_month" required />
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">SIM 1
                                    TYPE</label>
                                </div>
                                <div class="col-lg-4">
                                  <v-select :options="fetchSIM" v-model="sim_1" label="name" return-object></v-select>

                                  <div v-if="errors.sim1_type">
                                    <div v-for="error in errors.sim1_type" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <Datepicker v-model="forms.sim1_expire_date" :bootstrap-styling="true"
                                    format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>

                                  <div v-if="errors.sim1_expire_date">
                                    <div v-for="error in errors.sim1_expire_date" :key="error"
                                      class="alert alert-primary" role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">SIM 2
                                    TYPE</label>
                                </div>
                                <div class="col-lg-4">
                                  <v-select :options="fetchSIM" v-model="sim_2" label="name" return-object></v-select>

                                  <div v-if="errors.sim2_type">
                                    <div v-for="error in errors.sim2_type" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <Datepicker v-model="forms.sim2_expire_date" :bootstrap-styling="true"
                                    format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>

                                  <div v-if="errors.sim2_expire_date">
                                    <div v-for="error in errors.sim2_expire_date" :key="error"
                                      class="alert alert-primary" role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">HP
                                    1</label>
                                </div>
                                <div class="col-lg-4">
                                  <input type="text" class="form-control" id="hp1" name="hp1" placeholder=""
                                    v-model="forms.hp1" required />

                                  <div v-if="errors.hp1">
                                    <div v-for="error in errors.hp1" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <label for="defaultconfig" class="col-form-label"
                                    style="padding-left: 15px">WA</label>
                                  <input class="form-check-input" v-model="forms.wa_hp1"
                                    style="margin-top: 8px; margin-left: 8px" type="checkbox" value="1"
                                    id="flexCheckDefault" />
                                </div>
                              </div>

                              <div class="row" style="margin-top: 25px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">HP
                                    2</label>
                                </div>
                                <div class="col-lg-4">
                                  <input type="text" class="form-control" id="hp2" name="hp2" placeholder=""
                                    v-model="forms.hp2" />

                                  <div v-if="errors.hp2">
                                    <div v-for="error in errors.hp2" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <label for="defaultconfig" class="col-form-label"
                                    style="padding-left: 15px">WA</label>
                                  <input class="form-check-input" v-model="forms.wa_hp2"
                                    style="margin-top: 8px; margin-left: 8px" type="checkbox" value="1"
                                    id="flexCheckDefault" />
                                </div>
                              </div>

                              <div class="row" style="margin-top: 25px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">SAP
                                    CODE</label>
                                </div>
                                <div class="col-lg-10">
                                  <input type="text" class="form-control" id="sap_code" name="sap_code" placeholder=""
                                    v-model="forms.sap_code" />

                                  <div v-if="errors.sap_code">
                                    <div v-for="error in errors.sap_code" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 25px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("end_of_contract") }}</label>
                                </div>
                                <div class="col-lg-4">
                                  <Datepicker v-model="forms.end_contract" :bootstrap-styling="true" format="dd-MM-yyyy"
                                    placeholder="Please Select Date..."></Datepicker>

                                  <div v-if="errors.end_contract">
                                    <div v-for="error in errors.end_contract" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("stop_date") }}</label>
                                </div>

                                <div class="col-lg-4">
                                  <Datepicker v-model="forms.stop_date" :bootstrap-styling="true" format="dd-MM-yyyy"
                                    placeholder="Please Select Date..."></Datepicker>

                                  <div v-if="errors.stop_date">
                                    <div v-for="error in errors.stop_date" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("stop_reason") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <v-select :options="fetchStopReason" v-model="stop_reason" label="name"
                                    return-object></v-select>

                                  <div v-if="errors.stop_reason">
                                    <div v-for="error in errors.stop_reason" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("stop_remark") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <textarea class="form-control" v-model="forms.stop_remark"></textarea>

                                  <div v-if="errors.stop_remark">
                                    <div v-for="error in errors.stop_remark" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                                <div class="row" style="margin-top: 15px">
                                  <div class="col-lg-2">
                                    <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Driver
                                      Coordinator</label>
                                  </div>
                                  <div class="col-lg-10">
                                    <input class="form-control" v-model="forms.dc_name" name='dc_name' />

                                    <div v-if="errors.dc_name">
                                      <div v-for="error in errors.dc_name" :key="error" class="alert alert-primary"
                                        role="alert">
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                            </div>
                          </div>
                          <br />
                        </div>
                        <div class="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">
                          <h5>{{ $t("idCardAddress") }}</h5>
                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("address") }}</label>
                            </div>
                            <div class="col-lg-10">
                              <textarea class="form-control" v-model="forms.address_idcard"></textarea>

                              <div v-if="errors.address_idcard">
                                <div v-for="error in errors.address_idcard" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">RT</label>
                            </div>
                            <div class="col-lg-4">
                              <input class="form-control" v-model="forms.rt_idcard" type="number" name="rt" />
                              <div v-if="errors.rt_idcard">
                                <div v-for="error in errors.rt_idcard" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">RW</label>
                            </div>

                            <div class="col-lg-4">
                              <input class="form-control" v-model="forms.rw_idcard" type="number" name="rw" />

                              <div v-if="errors.rw_idcard">
                                <div v-for="error in errors.rw_idcard" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("provinceTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchProvince" v-model="province" label="province" return-object
                                @input="searchCity"></v-select>

                              <div v-if="errors.province_idcard">
                                <div v-for="error in errors.province_idcard" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("cityTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchCity" v-model="city" label="city" return-object
                                @input="searchRegion"></v-select>

                              <div v-if="errors.city_idcard">
                                <div v-for="error in errors.city_idcard" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("regionTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchRegion" v-model="region" label="district" return-object
                                @input="searchSubDistrict"></v-select>

                              <div v-if="errors.region_idcard">
                                <div v-for="error in errors.region_idcard" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("districtTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchDistrict" v-model="district" label="sub_district"
                                @input="searchPostalCode" return-object></v-select>

                              <div v-if="errors.district_idcard">
                                <div v-for="error in errors.district_idcard" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("postalCodeTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchPostalCode" v-model="postalCode" label="postal_code"
                                return-object></v-select>

                              <div v-if="errors.postal_code_idcard">
                                <div v-for="error in errors.postal_code_idcard" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <br />
                          <h5>{{ $t("domicileAddress") }}</h5>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("sameAsId") }}</label>
                            </div>
                            <div class="col-lg-10">
                              <input type="checkbox" v-model="forms.same_as_id" class="form-check-input"
                                v-on:change="checkSameID()" />
                              <!-- <div class="form-check">
                                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                      <label class="form-check-label" for="flexCheckDefault">
                                        Default checkbox
                                      </label>
                                    </div> -->

                              <!-- <div v-if="errors.addressDomicile">
                                      <div
                                        v-for="error in errors.addressDomicile"
                                        :key="error"
                                        class="alert alert-primary"
                                        role="alert"
                                      >
                                        <i data-feather="alert-circle"></i>
                                        {{ error }}
                                      </div>
                                    </div> -->
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("address") }}</label>
                            </div>
                            <div class="col-lg-10">
                              <textarea class="form-control" v-model="forms.address_domicile"></textarea>

                              <div v-if="errors.address_domicile">
                                <div v-for="error in errors.address_domicile" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">RT</label>
                            </div>
                            <div class="col-lg-4">
                              <input class="form-control" v-model="forms.rt_domicile" type="number" name="rt" />
                              <div v-if="errors.rt_domicile">
                                <div v-for="error in errors.rt_domicile" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">RW</label>
                            </div>

                            <div class="col-lg-4">
                              <input class="form-control" v-model="forms.rw_domicile" type="number" name="rw" />

                              <div v-if="errors.rw_domicile">
                                <div v-for="error in errors.rw_domicile" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("provinceTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchProvinceDomicile" v-model="provinceDomicile" label="province"
                                @input="searchCityDomicile" return-object></v-select>

                              <div v-if="errors.provinceDomicile">
                                <div v-for="error in errors.provinceDomicile" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("cityTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchCityDomicile" v-model="cityDomicile" label="city" return-object
                                @input="searchRegionDomicile"></v-select>

                              <div v-if="errors.cityDomicile">
                                <div v-for="error in errors.cityDomicile" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("regionTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchRegionDomicile" v-model="regionDomicile" label="district"
                                return-object @input="searchSubDistrictDomicile"></v-select>

                              <div v-if="errors.regionDomicile">
                                <div v-for="error in errors.regionDomicile" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("districtTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchDistrictDomicile" v-model="districtDomicile" label="sub_district"
                                return-object @input="searchPostalCodeDistrict"></v-select>

                              <div v-if="errors.districtDomicile">
                                <div v-for="error in errors.districtDomicile" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("postalCodeTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchPostalCodeDomicile" v-model="postalCodeDomicile"
                                label="postal_code" return-object></v-select>

                              <div v-if="errors.postalCodeDomicile">
                                <div v-for="error in errors.postalCodeDomicile" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="sibiling-info" role="tabpanel"
                          aria-labelledby="sibiling-info-tab">
                          <h5>Sibling Info</h5>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("nameTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <input class="form-control" v-model="forms.name_sibling" name="nameBilling" />

                              <div v-if="errors.name_sibling">
                                <div v-for="error in errors.name_sibling" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("relationShip") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchRelationShip" v-model="relationShip" label="name"
                                return-object></v-select>

                              <div v-if="errors.postalRelationShip">
                                <div v-for="error in errors.postalRelationShip" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">Hp</label>
                            </div>
                            <div class="col-lg-4">
                              <input class="form-control" v-model="forms.hp_sibling" name="hp" />

                              <div v-if="errors.hp_sibling">
                                <div v-for="error in errors.hp_sibling" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("provinceTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchProvinceSibiling" v-model="provinceSibiling" label="province"
                                return-object @input="searchCitySibiling"></v-select>

                              <div v-if="errors.provinceSibiling">
                                <div v-for="error in errors.provinceSibiling" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("cityTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchCitySibiling" v-model="citySibiling" label="city" return-object
                                @input="searchRegionSibiling"></v-select>

                              <div v-if="errors.citySibiling">
                                <div v-for="error in errors.citySibiling" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("regionTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchRegionSibiling" v-model="regionSibiling" label="district"
                                return-object @input="searchSubDistrictSibling"></v-select>

                              <div v-if="errors.regionSibiling">
                                <div v-for="error in errors.regionSibiling" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("districtTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchDistrictSibiling" v-model="districtSibiling" label="sub_district"
                                return-object @input="searchPostalCodeSibling"></v-select>

                              <div v-if="errors.districtSibiling">
                                <div v-for="error in errors.districtSibiling" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row" style="margin-top: 15px">
                            <div class="col-lg-2">
                              <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("postalCodeTxt") }}</label>
                            </div>
                            <div class="col-lg-4">
                              <v-select :options="fetchPostalCodeSibiling" v-model="postalCodeSibiling"
                                label="postal_code" return-object></v-select>

                              <div v-if="errors.postalCodeSibiling">
                                <div v-for="error in errors.postalCodeSibiling" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="recruitment-info" role="tabpanel"
                          aria-labelledby="recruitment-info-tab">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label"
                                    style="padding-left: 15px">Recruitment Source</label>
                                </div>
                                <div class="col-lg-10">
                                  <v-select :options="fetchRecruitmentSource" v-model="recruitmentSource" label="name"
                                    return-object></v-select>

                                  <div v-if="errors.recruitmentSource">
                                    <div v-for="error in errors.recruitmentSource" :key="error"
                                      class="alert alert-primary" role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("driver_id") }}</label>
                                </div>
                                <div class="col-lg-10">

                                  <v-select :options="fetchDriverId" v-model="driverId"
                                    :disabled="recruitmentSource == 'Karyawan'" label="driver_id" return-object
                                    @input="changeDriverIdResource"></v-select>

                                  <div v-if="errors.driverId">
                                    <div v-for="error in errors.driverId" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("nameTxt") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <input class="form-control" v-model="forms.recruiter_name" name="recruiter_name" />

                                  <div v-if="errors.recruiter_name">
                                    <div v-for="error in errors.recruiter_name" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("name_of_id_card") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <input class="form-control" v-model="forms.recruiter_id_card_number"
                                    name="recruiter_id_card_number" />

                                  <div v-if="errors.recruiter_id_card_number">
                                    <div v-for="error in errors.recruiter_id_card_number" :key="error"
                                      class="alert alert-primary" role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("locationPhoneNumber") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <input class="form-control" v-model="forms.phone_number" name="phone_number" />

                                  <div v-if="errors.phone_number">
                                    <div v-for="error in errors.phone_number" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                      $t("handphone") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <input class="form-control" v-model="forms.handphone_number"
                                    name="handphone_number" />

                                  <div v-if="errors.handphone_number">
                                    <div v-for="error in errors.handphone_number" :key="error"
                                      class="alert alert-primary" role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                                    $t("relationShip") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <input class="form-control" v-model="forms.relationship_recruiter"
                                    name="relationShip_recruitment_info" />

                                  <div v-if="errors.relationship_recruiter">
                                    <div v-for="error in errors.relationship_recruiter" :key="error"
                                      class="alert alert-primary" role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div class="row" style="margin-top: 15px">
                                <div class="col-lg-2">
                                  <label for="defaultconfig" class="col-form-label" style="padding-left: 15px">{{
                                    $t("remarks_txt") }}</label>
                                </div>
                                <div class="col-lg-10">
                                  <textarea class="form-control" v-model="forms.remark"></textarea>

                                  <div v-if="errors.remark">
                                    <div v-for="error in errors.remark" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div
                            class="tab-pane fade"
                            id="driver-id-info"
                            role="tabpanel"
                            aria-labelledby="driver-id-info"
                          >
                            <div class="row">
                              <table-driver-id
                                :detailOrder="fetchTripAnalysis"
                                
                              ></table-driver-id>
                            </div>
                          </div> -->
                      </div>
                    </div>
                    <!--begin::loading-data-->

                    <!--end::loading-data-->
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-warning text-black fw-bolder btn-icon-text" @click="backForm" type="button">
                      <i class="link-icon" data-feather="arrow-left"></i>
                      {{ $t("backMess") }}
                    </button>

                    &nbsp;&nbsp;&nbsp;
                    <button class="btn btn-primary float-end btn-sm" type="submit">
                      <i class="link-icon" data-feather="save"></i>
                      {{ $t("submitFormTxt") }}
                    </button>
                  </div>
                </form>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import tableDriverId from "@/components/master/driver/TableDriverId.vue";
// import allHistoryDelivery from "@/components/master/driver/AllHistoryDelivery.vue";
// import onTimeHistoryDelivery from "@/components/master/driver/OnTimeDelivery.vue";
// import claimHistoryDelivery from "@/components/master/driver/ClaimDelivery.vue";
// import pokHistoryDelivery from "@/components/master/driver/PokDelivery.vue";
// import serviceHistoryDelivery from "@/components/master/driver/ServiceDelivery.vue";
// import podHistoryDelivery from "@/components/master/driver/PodDelivery.vue";
// import accidentHistoryDelivery from "@/components/master/driver/AccidentDelivery.vue";
// import trafficViolationHistoryDelivery from "@/components/master/driver/TrafficViolationHistoryDelivery.vue";
// import propertyDamageHistoryDelivery from "@/components/master/driver/PropertyDamageHistoryDelivery.vue";
// import workingDaysHistoryDelivery from "@/components/master/driver/WorkingDaysHistoryDelivery.vue";
// import trainingDriverDelivery from "@/components/master/driver/TrainingDriverDelivery.vue";
// import bakDriverDelivery from "@/components/master/driver/BakDriver.vue";
// import equipmentDriverComponenent from "@/components/master/driver/equipmentDriver.vue";
// import driverHistoryStatus from "@/components/master/driver/DriverHistoryStatus.vue";
// import depositComponent from "@/components/master/driver/DepositHistoryDriver.vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "DriverAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    Datepicker,
    // "table-driver-id": tableDriverId,
    // "all-history-delivery":allHistoryDelivery,
    // "ontime-history-delivery":onTimeHistoryDelivery,
    // "claim-history-delivery":claimHistoryDelivery,
    // "pok-history-delivery":pokHistoryDelivery,
    // "service-history-delivery":serviceHistoryDelivery,
    // "pod-history-delivery":podHistoryDelivery,
    // "accident-history-delivery":accidentHistoryDelivery,
    // "traffic-history-delivery":trafficViolationHistoryDelivery,
    // "property-damage-history-delivery":propertyDamageHistoryDelivery,
    // "working-days-history-delivery":workingDaysHistoryDelivery,
    // "training-driver-delivery":trainingDriverDelivery,
    // "bak-driver":bakDriverDelivery,
    // "equipment-driver":equipmentDriverComponenent,
    // "driver-history-status":driverHistoryStatus,
    // "deposit-history":depositComponent
  },
  data() {
    return {
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      maried_status: "",
      driver_substatus: "",
      fetchMariedStatus: [],
      religion: "",
      fetchReligion: [],
      fetchProvince: [],
      province: "",
      fetchCity: [],
      city: "",
      fetchRegion: [],
      region: "",
      fetchDistrict: [],
      district: "",
      fetchPostalCode: [],
      postalCode: "",
      fetchProvinceDomicile: [],
      provinceDomicile: "",
      fetchCityDomicile: [],
      cityDomicile: "",
      fetchRegionDomicile: [],
      regionDomicile: "",
      fetchDistrictDomicile: [],
      districtDomicile: "",
      fetchPostalCodeDomicile: [],
      postalCodeDomicile: "",
      fetchRelationShip: [],
      relationShip: "",
      fetchProvinceSibiling: [],
      provinceSibiling: "",
      fetchCitySibiling: [],
      gender: "",
      fetchGender: ['Laki-laki', 'Perempuan'],
      citySibiling: "",
      fetchRegionSibiling: [],
      regionSibiling: "",
      fetchDistrictSibiling: [],
      districtSibiling: "",
      fetchPostalCodeSibiling: [],
      postalCodeSibiling: "",
      fetchRecruitmentSource: [],
      recruitmentSource: "",
      fetchDriverId: [],
      driverId: "",
      fetchTripAnalysis: [],
      periode_awal: "",
      periode_akhir: "",
      periode_awal_deposit: "",
      periode_akhir_deposit: "",
      education: "",
      fetchEducation: [],
      stop_reason: "",
      fetchStopReason: [],
      positions: "",
      fetchPosition: [],
      pool: "",
      fetchPool: [],
      project: "",
      fetchProject: [],
      fetchBranch: [],
      branch: [],
      permision_role: [],
      userData: "",
      driver_status: "",
      ethnic: "",
      fetchEthnic: [],
      sim_1: "",
      sim_2: "",
      fetchSIM: [],
      fetchStatus: [],
      fetchSub: [],
      companyCode: "",

      forms: {
        gender: "",
        driver_id: "",
        driver_name: this.$route.query?.driver_name,
        branch_id: "",
        site_name: "",
        project_name: "",
        driver_position: "",
        hp1: "",
        wa_hp1: "",
        hp2: "",
        wa_hp2: "",
        sap_code: "",
        driver_status: "",
        driver_substatus: "",
        status_remark: "",
        join_date: "",
        nickname: "",
        birth_place: this.$route.query?.birth_place,
        birth_date: this.$route.query?.birth_date,
        sim_type: "",
        sim_expire_date: "",
        ethnic: "",
        marital_status: "",
        children_number: 0,
        id_card_number: this.$route.query?.id_card_number,
        sim_no: this.$route.query?.sim_no,
        education: "",
        religion: "",
        sim1_type: "",
        sim1_expire_date: "",
        sim2_type: "",
        sim2_expire_date: "",
        end_contract: "",
        stop_date: "",
        stop_reason: "",
        stop_remark: "",

        address_idcard: "",
        rt_idcard: "",
        rw_idcard: "",
        province_idcard: "",
        city_idcard: "",
        region_idcard: "",
        district_idcard: "",
        hp_idcard: "",
        name_idcard: this.$route.query?.driver_name,
        relationship_idcard: "",
        address_domicile: "",
        rt_domicile: "",
        rw_domicile: "",
        province_domicile: "",
        city_domicile: "",
        region_domicile: "",
        district_domicile: "",
        hp_domicile: "",
        name_domicile: "",
        relationship_domicile: "",
        address_sibling: "",
        rt_sibling: "",
        rw_sibling: "",
        province_sibling: "",
        city_sibling: "",
        region_sibling: "",
        district_sibling: "",
        hp_sibling: "",
        postal_code_domicile: "",
        postal_code_idcard: "",
        name_sibling: "",
        relationship_sibling: "",
        recruitment_source: "",
        master_driver_id: "",
        recruiter_name: "",
        recruiter_id_card_number: "",
        recruiter_driver_id: "",
        phone_number: "",
        handphone_number: "",
        relationship_recruiter: "",
        remark: "",
        pic: "",
        dc_name:"",
      },
      photo_temporary: null,
      photo: "",
      fetchTypeTruck: [],
    };
  },
  watch: {},
  methods: {

    changeDriverIdResource() {

      const baseURI = this.$settings.endPointCt + `master-driver?driver_id=` + this.driverId.driver_id;
      return this.$http.get(baseURI).then((response) => {
        this.forms.recruiter_name = response.data.datas.data[0].driver_name;
        this.forms.recruiter_id_card_number = response.data.datas.data[0].id_card_number;
        this.forms.phone_number = response.data.datas.data[0].hp1;
        this.forms.handphone_number = response.data.datas.data[0].hp2;
        this.forms.relationship_recruiter = "";
        //this.fetchDriverId = response.data.datas.data;
      });
    },

    changeStatus(ev) {
      this.driver_substatus = "";
      const baseURI =
        this.$settings.endPointCt + `master-lookup/dependent?unique_id=stdri&parent=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchSub = response.data.datas;
      });
    },
    removeImages() {
      this.$refs.photo = "";
      this.photo_temporary = null;
    },

    handleFileUpload(event) {
      this.photo = event.target.files[0];
      this.photo_temporary = URL.createObjectURL(this.photo);
    },

    changeJoinDate() {
      let start = moment(this.forms.join_date);
      let end = moment(new Date());
      let duration = moment.duration(end.diff(start));

      let month = duration.months();
      let years = duration.years();

      this.forms.working_month = month;
      this.forms.working_year = years;
      //this.dateDiff = Math.round(month);
      //console.log(this.dateDiff);
    },

    changeAgeDate() {
      let start = moment(this.forms.birth_date);
      let end = moment(new Date());
      let duration = moment.duration(end.diff(start));

      let years = duration.years();

      this.forms.age = years;
      //this.forms.working_year=years;
      //this.dateDiff = Math.round(month);
      //console.log(this.dateDiff);
    },

    downloadExcel() { },

    resetForm() {
      this.removeImages();
      this.forms = "";
    },

    backForm() {
      window.location.href = "/driver";
    },

    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },

    loadStatus() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=stdri`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchStatus = response.data.datas;
      });
    },

    loadProvince() {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-province/indonesia?limit=200`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchProvince = response.data.datas.data;
        this.fetchProvinceDomicile = response.data.datas.data;
        this.fetchProvinceSibiling = response.data.datas.data;
      });
    },
    loadTypeTruck() {
      const baseURI = this.$settings.endPointCt + `type-truck?limit=200`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncTypeTruck(ev) {
      console.log(ev);
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },

    checkSameID() {
      console.log(this.forms.same_as_id);
      console.log(this.province);
      this.provinceDomicile = this.province;
      if (this.forms.same_as_id == true) {
        this.provinceDomicile = this.province;
        this.cityDomicile = this.city;
        this.regionDomicile = this.region;
        this.districtDomicile = this.district;
        this.postalCodeDomicile = this.postalCode;
        this.forms.address_domicile = this.forms.address_idcard;
        this.forms.rt_domicile = this.forms.rt_idcard;
        this.forms.rw_domicile = this.forms.rw_idcard;
      } else {
        this.provinceDomicile = [];
        this.cityDomicile = [];
        this.regionDomicile = [];
        this.districtDomicile = [];
        this.postalCodeDomicile = [];
        this.forms.address_domicile = "";
        this.forms.rt_domicile = "";
        this.forms.rw_domicile = "";
      }
    },

    searchCity(ev) {
      console.log(ev.province);
      const baseURI =
        this.$settings.endPoint + `postal-code/select-city/` + ev.province;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCity = response.data.datas;
      });
    },

    searchCityDomicile(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-city/` + ev.province;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCityDomicile = response.data.datas;
      });
    },

    searchCitySibiling(ev) {
      console.log(ev.province);
      const baseURI =
        this.$settings.endPoint + `postal-code/select-city/` + ev.province;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCitySibiling = response.data.datas;
      });
    },



    searchSubDistrict(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-sub-district/` + ev.district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDistrict = response.data.datas;
      });
    },

    searchSubDistrictDomicile(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-sub-district/` + ev.district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDistrictDomicile = response.data.datas;
      });
    },

    searchSubDistrictSibling(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-sub-district/` + ev.district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDistrictSibiling = response.data.datas;
      });
    },

    searchRegion(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-district/` + ev.city;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas;
      });
    },

    searchPostalCode(ev) {
      var district = this.region.district;
      const baseURI =
        this.$settings.endPoint + `postal-code?district=` + district + `&sub_district=` + ev.sub_district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCode = response.data.datas.data;
      });
    },

    searchPostalCodeDistrict(ev) {
      var district = this.regionDomicile.district;
      const baseURI =
        this.$settings.endPoint + `postal-code?district=` + district + `&sub_district=` + ev.sub_district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCodeDomicile = response.data.datas.data;
      });
    },


    searchPostalCodeSibling(ev) {
      var district = this.regionSibiling.district;
      const baseURI =
        this.$settings.endPoint + `postal-code?district=` + district + `&sub_district=` + ev.sub_district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCodeSibiling = response.data.datas.data;
      });
    },


    searchRegionSibiling(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-district/` + ev.city;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegionSibiling = response.data.datas;
      });
    },

    searchRegionDomicile(ev) {
      const baseURI =
        this.$settings.endPoint + `postal-code/select-district/` + ev.city;
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegionDomicile = response.data.datas;
      });
    },

    loadPostalCode() {
      const baseURI = this.$settings.endPoint + `postal-code`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCode = response.data.datas.data;
        this.fetchPostalCodeDomicile = response.data.datas.data;
        this.fetchPostalCodeSibiling = response.data.datas.data;
      });
    },

    // loadPostalCodeDomicile(){
    //   const baseURI = this.$settings.endPoint + `postal-code`;
    //   return this.$http.get(baseURI).then((response) => {
    //     this.fetchPostalCodeDomicile = response.data.datas.data;
    //   });
    // },

    loadSub() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=susta`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchSub = response.data.datas;
      });
    },

    loadMaried() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=marit`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchMariedStatus = response.data.datas;
      });
    },

    loadSim() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=simtp`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchSIM = response.data.datas;
      });
    },

    loadEthnic() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=ethni`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchEthnic = response.data.datas;
      });
    },

    loadPool() {
      const baseURI = this.$settings.endPointCt + `pool-branch?limit=200`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPool = response.data.datas.data;
      });
    },

    loadPosition() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=posit`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPosition = response.data.datas;
      });
    },

    loadProject() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=proje`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchProject = response.data.datas;
      });
    },

    loadRelationShip() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=relat`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchRelationShip = response.data.datas;
      });
    },

    loadReligion() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=relig`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchReligion = response.data.datas;
      });
    },

    loadDriver() {
      const baseURI = this.$settings.endPointCt + `master-driver`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchDriverId = response.data.datas.data;
      });
    },

    loadEducation() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=educa`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchEducation = response.data.datas;
      });
    },

    loadReason() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=strea`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchStopReason = response.data.datas;
      });
    },

    loadRecruitmenSource() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=resou`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchRecruitmentSource = response.data.datas;
      });
    },

    asyncLoadBranch(ev) {
      const baseURI = this.$settings.endPointCt + `branch?branch_name=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },

    submitData() {
      console.log(this.maried_status);
      this.fade(true);
      this.forms.branch_id = this.branch.branch_id;
      this.forms.join_date = moment(String(this.forms.join_date)).format(
        "YYYY-MM-DD"
      );
      this.forms.birth_date = moment(String(this.forms.birth_date)).format(
        "YYYY-MM-DD"
      );
      this.forms.sim_expire_date = moment(
        String(this.forms.sim_expire_date)
      ).format("YYYY-MM-DD");

      this.forms.sim1_expire_date = moment(
        String(this.forms.sim1_expire_date)
      ).format("YYYY-MM-DD");

      this.forms.end_contract = moment(
        String(this.forms.end_contract)
      ).format("YYYY-MM-DD");

      this.forms.stop_date = moment(
        String(this.forms.stop_date)
      ).format("YYYY-MM-DD");

      this.forms.sim2_expire_date = moment(
        String(this.forms.sim2_expire_date)
      ).format("YYYY-MM-DD");

      this.forms.gender = this.gender;
      this.forms.branch_id = this.branch.branch_id;
      this.forms.driver_name = this.forms.name_idcard;
      this.forms.driver_position = this.positions;
      this.forms.site_name = this.pool.pool_name;
      this.forms.project_name = this.project;
      this.forms.education = this.education;
      this.forms.religion = this.religion;
      this.forms.ethnic = this.ethnic;
      this.forms.stop_reason = this.stop_reason;
      this.forms.province_idcard = this.province.province;
      this.forms.city_idcard = this.city.city;
      this.forms.region_idcard = this.region.district;
      this.forms.district_idcard = this.district.sub_district;
      this.forms.postal_code_idcard = this.postalCode.postal_code;
      this.forms.marital_status = this.maried_status;
      this.forms.sim1_type = this.sim_1;
      this.forms.sim2_type = this.sim_2;

      this.forms.province_domicile = this.provinceDomicile.province;
      this.forms.city_domicile = this.cityDomicile.city;
      this.forms.region_domicile = this.regionDomicile.district;
      this.forms.district_domicile = this.districtDomicile.sub_district;
      this.forms.postal_code_domicile = this.postalCodeDomicile.postal_code;

      this.forms.province_sibling = this.provinceSibiling.province;
      this.forms.city_sibling = this.citySibiling.city;
      this.forms.region_sibling = this.regionSibiling.district;
      this.forms.district_sibling = this.districtSibiling.sub_district;
      this.forms.postal_code_sibling = this.postalCodeSibiling.postal_code;

      this.forms.relationship_sibling = this.relationShip;

      this.forms.recruitment_source = this.recruitmentSource;
      this.forms.recruiter_driver_id = this.driverId.driver_id;
      this.forms.photo = this.photo;

      this.forms.driver_status = this.driver_status;
      this.forms.driver_substatus = this.driver_substatus;

      if (this.forms.wa_hp1 == true) {
        this.forms.wa_hp1 = 1;
      } else {
        this.forms.wa_hp1 = 0;
      }

      if (this.forms.wa_hp2 == true) {
        this.forms.wa_hp2 = 1;
      } else {
        this.forms.wa_hp2 = 0;
      }

      let formData = new FormData();

      Object.keys(this.forms).forEach((key) => {
        formData.append(key, this.forms[key]);
      });

      // for (let i = 0; i < this.forms.length; i++) {
      //   console.log(this.forms[i]);
      //   formData.append(this.forms[i], this.forms[i]);
      // }

      // this.forms.forEach((number, index, array) => {
      //     console.log(array);
      //     console.log(number);
      //     console.log(index);
      // });

      console.log(this.forms);
      const baseURI = this.$settings.endPointCt + "master-driver/create";
      this.$http
        .post(baseURI, formData)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            console.log(response.data.datas.user_id);
            var params = this.$onRandom(response.data.datas.master_driver_id);
            window.location.href =
              "/driver/detail/" + params + "?status=200&msg=Successfully";
            this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {

      // this.forms.driver_name=this.$route.query?.driver_name;

      // this.forms.id_card_number=this.$route.query?.id_card;
      // console.log(this.forms.id_card_number);
      // this.forms.sim_no=this.$route.query?.sim_no;


      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MasterDriverController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {
    this.loadBranch();
  },
  mounted() {
    this.fetchIt();
    this.loadStatus();
    this.loadSub();
    this.loadPosition();
    this.loadPool();
    this.loadMaried();
    this.loadEducation();
    this.loadProject();
    this.loadReligion();
    this.loadEthnic();
    this.loadSim();
    this.loadReason();
    this.loadRelationShip();
    this.loadProvince();
    // this.loadRegion();
    this.loadPostalCode();
    this.loadRecruitmenSource();
    this.loadDriver();
    this.loadTypeTruck();




  },
};
</script>
