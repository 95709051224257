import TransportOrderCt from "@/components/orders/control-tower/TransportOrderCt.vue";
import TransportOrderCtDetail from "@/components/orders/control-tower/TransportOrderCtDetail.vue";
import TransportOrderCtEdit from "@/components/orders/control-tower/TransportOrderCtEdit.vue";

let routesTransportOrderCT = [
    {
      path: '',
      meta: {
        title: "Orders",
      },
      name: "TransportOrderCt",
      component: TransportOrderCt
    },
    
    {
      path: 'detail/:id',
      meta: {
        title: "Order Detail",
      },
      name: "TransportOrderCtDetail",
      component: TransportOrderCtDetail
    },
    
    {
      path: 'edit/:id',
      meta: {
        title: "Order Edit",
      },
      name: "TransportOrderCtEdit",
      component: TransportOrderCtEdit
    },
     
    
]

export default routesTransportOrderCT
  